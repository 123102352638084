import React from 'react';
import '../scss/DropdownProducto.scss';
import '../scss/bootstrap.scss';

function DropdownProducto(props) {
    return (
      <div className="DropdownProducto mb-5">
            <div className="accordion" id="accordionExample">
            <div className="card">
                <div className="card-header" id="headingTwo">
                <h2 className="mb-0">
                    <button className="btn btn-link btn-block text-left collapsed d-flex justify-content-space-between align-items-center align-items-md-start pl-0" type="button" data-toggle="collapse" data-target={`#${props.dataTarget}`} aria-expanded="false" aria-controls="collapseTwo">
                    {props.titleDropProduct}
                    <img src="/assets/arrow-bottom-blue.svg" alt="flecha" className="d-md-none arrow-mobile"/>
                    <div className=" d-none  d-md-flex justify-content-space-between align-items-start">
                        <div className="buttonDrop">
                            <p className="d-flex align-items-center">conocer más
                            <img src="/assets/arrow-bottom.svg" alt="flecha"/>
                            </p>
                        </div>
                        <div className="buttonClose">
                            <p>X</p>
                        </div>
                    </div>
                    </button>
                </h2>
                </div>
                <div id={props.dataTarget} className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                <div className="card-body text-left">
                    <p className="bajada">
                    {props.bajada}
                    </p>
                    <div className="row">
                        <div className="col-md-6">
                            <p>{props.textOne}</p>
                        </div>
                        <div className="col-md-6">
                            <p>{props.textTwo}</p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
                
            </div>
      </div>
    );
  }
  
  export default DropdownProducto;
  