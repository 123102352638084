import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import {BrowserRouter as Router, HashRouter, Switch,Route,Link} from "react-router-dom";
import DropdownMenu from './DropdownMenu'
import '../scss/Header.scss';
const NoMatch = () => <div>404 Not Found</div>;
const ScrollToTop = (props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
const Header = (props) => {
    const [menuOpen, setMenuOpen] = useState(false)

    const toggleMenu = () => setMenuOpen(!menuOpen)

    return (
        <div className="header">
          <div className="container">
            <div className="d-md-none">
              <div className="row justify-content-between align-items-center m-auto h-100">
                <div className="col-8 col-lg-2 logo">
                    <Link to="/"><img className="logomaniseros" src="/assets/logo.svg" alt=""/></Link>
                </div>
                <div className={`menu-btn ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                  <div className="menu-btn__burger">
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-none d-md-flex align-items-center h-100">
              <div className="col-md-5 d-flex justify-content-start d-flex align-items-center h-100">
                  <Link to="/"><img className="logo" src="./assets/logo.svg"/></Link>
              </div>
              <div className="col-md-7">
                <div className="row justify-content-between">
                  <Link to="/sobremani">SobreManí</Link>
                  <Link to="/programas">Programas</Link>
                  <Link to="/producto">Producto</Link>
                  <p className="nav-link dropdown-toggle mb-0"  href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" to="#">Aliados</p>
                              <div className="dropdown-menu p-0" aria-labelledby="navbarDropdown">
                                  <ul className="collapseNav">
                                      <li>
                                          <Link to="/fedea">Fedea</Link>
                                      </li>
                                      <li>
                                          <Link to="/basf">Basf</Link>
                                      </li>
                                      <li>
                                          <Link to="/bayer">Bayer</Link>
                                      </li>
                                      <li>
                                          <Link to="/corteva">Corteva</Link>
                                      </li>
                                      <li>
                                          <Link to="/nova">Nova</Link>
                                      </li>
                                      <li>
                                          <Link to="/oroverde">OroVerde</Link>
                                      </li>
                                      <li>
                                          <Link to="/summit">Summit Agro</Link>
                                      </li>
                                      <li>
                                          <Link to="/syngenta">Syngenta</Link>
                                      </li>
                                  </ul>
                              </div>
                  <Link to="/contacto">Contacto</Link>
                </div>
              </div>
            </div>
            
          </div>
          <div className="d-md-none">
              <div className={`d-none ${menuOpen ? 'menuBurger' : ''}`}>
                <div className="row">
                    <div className="col-12">
                        <ul className="d-flex flex-column px-3">
                            <li className="row mx-1"><Link to="/sobremani" onClick={toggleMenu}>SobreManí</Link><img src="/assets/arrow-next-blue.svg" alt="flecha" className="ml-3 flechitaIn"/></li>
                            <li className="row mx-1"><Link to="/programas" onClick={toggleMenu}>Programas</Link><img src="/assets/arrow-next-blue.svg" alt="flecha" className="ml-3 flechitaIn"/></li>
                            <li className="row mx-1"><Link to="/producto" onClick={toggleMenu}>Producto</Link><img src="/assets/arrow-next-blue.svg" alt="flecha" className="ml-3 flechitaIn"/></li>
                            <li className="mx-2 px-2">
                              <DropdownMenu titleDropPrograms="Aliados" 
                              dataTarget="aliados"
                              linkOne="/fedea"
                              linkTwo="/basf"
                              linkThree="/bayer"
                              linkFour="/corteva"
                              linkFive="/nova"
                              linkSix="/oroverde"
                              linkSeven="/summit"
                              linkEight="/syngenta"
                              handleClick={toggleMenu}/>
                              </li>
                            <li className="row mx-1"><Link to="/contacto" onClick={toggleMenu}>Contacto</Link><img src="/assets/arrow-next-blue.svg" alt="flecha" className="ml-3 flechitaIn"/></li>
                        </ul>
                    </div>
                </div>
              </div>
            </div>
        </div>
    );
  }
  
  export default Header;