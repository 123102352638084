import React, { useEffect, useState }  from 'react';
import '../scss/bootstrap.scss';
import '../scss/DropdownContacto.scss';
import Map from './Map'
import { useRecoilState } from 'recoil';
import { formState } from '../atom'
const SOBREMANI_URL = 'api/contacto'
const SOBREMANISEROS_URL = 'api/contacto'


function DropdownContacto(props) {
  const [formSended, setFormSended] = useState(false)

  const blockButton = () => setFormSended(true)
  const submitProgramasD = () => {
      blockButton()
      const modal = document.getElementById("modal-contacto")
      if(name && email && surname && phone ){
          modal.style.display = "block";
      }
      submitProgramasD()
  }

  const [sobremaniMessage, setSobremaniMessage] = useState('')
  const [inversionesOrganizacion, setinversionesOrganizacion] = useState('')
  const [inversionesTelefono, setinversionesTelefono] = useState('')
  const [sobremaniserosMessage, setsobremaniserosMessage] = useState('')
  const [form, setForm] = useRecoilState(formState)
  const {name, email, surname, phone} = form

  let lat = '-33.3936'
  let lng = '-63.3039'

  // --------- Enviar Programas
  const submitProgramas = (e) =>{
    
    blockButton()
    const modal = document.getElementById("modalubicacion")
    modal.style.display = "block";
    e.preventDefault()
    const body = {
      nombre: name, apellido: surname, mail: email, telefono: phone,latPrograma : lat, longPrograma: lng, tipoForm: 'programas'
    }
    fetch(SOBREMANI_URL,{
      method: 'POST',
      headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(response =>{
      if(response.status === 201){
        setokmsjc(['¡Muchas Gracias!'])
      } else {
        const errorMessage = ('ERROR', response.status + [' Falló el Envio'])
        setErrorsc([errorMessage])
      }
    })
    .catch(errorsc => {
      setErrorsc([errorsc])
    })
  }
  useEffect(() => {
    //---------------------MODAL--------------------------
    const modal = document.getElementById("modalubicacion")
    const btn = document.getElementById("btn-contacto")
    const span = document.getElementById("close")

    span.onclick = function() {
        modal.style.display = "none";
    }
    
    window.onclick = function(event) {
        if (event.target == modal) {
            modal.style.display = "none";
        }
    }
  })



  // --------- Enviar Inveriones
  const [errorsc, setErrorsc] = useState([])
  const [okmsjc, setokmsjc] = useState([])
  const submitInversiones = (e) => {
    blockButton()
    const modal = document.getElementById("modalubicacion")
    modal.style.display = "block";
    e.preventDefault()
    const body = {
      nombre: name, apellido: surname, mail: email, telefono: phone, organizacionInversion:inversionesOrganizacion, telefono2Inversion:inversionesTelefono, tipoForm: 'inversiones'
    }
    fetch(SOBREMANI_URL, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(response =>{
      if(response.status === 201){
        setokmsjc(['¡Muchas Gracias!'])
      } else {
        const errorMessage = ('ERROR', response.status + [' Falló el Envio'])
        setErrorsc([errorMessage])
      }
    })
    .catch(errorsc => {
      setErrorsc([errorsc])
    })
  }
  useEffect(() => {
    //---------------------MODAL--------------------------
    const modal = document.getElementById("modalubicacion")
    const btn = document.getElementById("btn-contacto")
    const span = document.getElementById("close")

    span.onclick = function() {
        modal.style.display = "none";
    }
    
    window.onclick = function(event) {
        if (event.target == modal) {
            modal.style.display = "none";
        }
    }
  })


  
  
  // --------- Enviar Sobre Mani
  const submitSobremani = (e) => {
    blockButton()
    const modal = document.getElementById("modalubicacion")
    modal.style.display = "block";
    e.preventDefault()
    const body = {
      nombre: name, apellido: surname, mail: email, telefono: phone, mensajeMani: sobremaniMessage, tipoForm: 'mani'
    }

    fetch(SOBREMANI_URL, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(response =>{
      if(response.status === 201){
        setokmsjc(['¡Muchas Gracias!'])
      } else {
        const errorMessage = ('ERROR', response.status + [' Falló el Envio'])
        setErrorsc([errorMessage])
      }
    })
    .catch(errorsc => {
      setErrorsc([errorsc])
    })
  }
  useEffect(() => {
    //---------------------MODAL--------------------------
    const modal = document.getElementById("modalubicacion")
    const btn = document.getElementById("btn-contacto")
    const span = document.getElementById("close")

    span.onclick = function() {
        modal.style.display = "none";
    }
    
    window.onclick = function(event) {
        if (event.target == modal) {
            modal.style.display = "none";
        }
    }
  })

  const onMarkerDragEnd = (newlat, newlng) => {
    lat = newlat
    lng = newlng
  }

  const onPlaceSelected = (newlat, newlng) => {
    lat = newlat
    lng = newlng
  }



  
  // --------- Enviar Sobre Maniseros
    const submitSobremaniseros = (e) => {
      blockButton()
    const modal = document.getElementById("modalubicacion")
    modal.style.display = "block";
    e.preventDefault()
    const body = {
      nombre: name, apellido: surname, mail: email, telefono: phone, mensajeManiseros: sobremaniserosMessage, tipoForm: 'maniseros'
    }

    fetch(SOBREMANISEROS_URL, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(response =>{
      if(response.status === 201){
        setokmsjc(['¡Muchas Gracias!'])
      } else {
        const errorMessage = ('ERROR', response.status + [' Falló el Envio'])
        setErrorsc([errorMessage])
      }
    })
    .catch(errorsc => {
      setErrorsc([errorsc])
    })
  }
    useEffect(() => {
      //---------------------MODAL--------------------------
      const modal = document.getElementById("modalubicacion")
      const btn = document.getElementById("btn-contacto")
      const span = document.getElementById("close")

      span.onclick = function() {
          modal.style.display = "none";
      }
      
      window.onclick = function(event) {
        if (event.target == modal) {
            modal.style.display = "none";
        }
      }
    })

    const [ collapsedElement, setCollapsedElement ] = useState('none')
  
    return (

      <div className="DropdownContacto w-100">
        {/* Modal */}
        <div className="ubicationmodal position-absolute" id="modalubicacion">
          <div className="position-relative w-100 h-100">
            <div id="modal-contacto" className="modale position-absolute">
              <div className="modalaaa">
                    <span id="close" className="d-flex justify-content-center ">&times;</span>
                    <p className="enviandomsj">Enviando mensaje:</p>
                    {errorsc && <p className="error">{errorsc[0]}</p>}
                    {okmsjc && <p className="okmsj">{okmsjc[0]}</p>}
                </div>
            </div>
          </div>
        </div>
        
        {/* Botones */}
        <p className="row flex-column flex-md-row w-100 justify-content-between m-0">
          <a className="btn-w btn btn-primary d-flex justify-content-between align-items-center" type="button" data-toggle="collapse" data-target="#programas" aria-expanded="false" aria-controls="collapseExample"  onClick={() => setCollapsedElement(collapsedElement === 'programas' ? 'none' : 'programas')}>
            PROGRAMAS
            <img src="/assets/arrow-bottom.svg" alt="flecha" className="arrow-bottom" />
          </a>
          <a className="btn-w btn btn-primary col-md-3 d-flex justify-content-between align-items-center" type="button" data-toggle="collapse" data-target="#inversiones" aria-expanded="false" aria-controls="collapseExample" onClick={() => setCollapsedElement(collapsedElement === 'inversiones' ? 'none' : 'inversiones')}>
            INVERSIONES
            <img src="/assets/arrow-bottom.svg" alt="flecha" className="arrow-bottom" />
          </a>
          <a className="btn-w btn btn-primary col-md-3 d-flex justify-content-between align-items-center" type="button" data-toggle="collapse" data-target="#sobremani" aria-expanded="false" aria-controls="collapseExample" onClick={() => setCollapsedElement(collapsedElement === 'sobremani' ? 'none' : 'sobremani')}>
            SOBRE MANÍ
            <img src="/assets/arrow-bottom.svg" alt="flecha" className="arrow-bottom" />
          </a>
          <a className="btn-w btn btn-primary col-md-3 d-flex justify-content-between align-items-center" type="button" data-toggle="collapse" data-target="#sobremaniseros" aria-expanded="false" aria-controls="collapseExample" onClick={() => setCollapsedElement(collapsedElement === 'sobremaniseros' ? 'none' : 'sobremaniseros')}>
            SOBRE MANISEROS
            <img src="/assets/arrow-bottom.svg" alt="flecha" className="arrow-bottom" />
          </a>
        </p>

        {/* Programas */}
        <div class="collapse multi-collapse" id="programas">
          <div class={`card card-body px-0 ${collapsedElement !== 'programas' ? 'collapse' : ''}`}>
            <p className="title-contacto">Estamos para ayudarte</p>
            <p className="text-left">Ubica tu campo en el mapa</p>
            <div className="col-12 px-0">
              <Map
              google={props.google}
              center={{lat: -33.3936, lng: -63.3039}}
              height="400px"
              zoom={8}
              onMarkerDragEnd={onMarkerDragEnd}
              onPlaceSelected={onPlaceSelected}
              />
              <div className="row align-items-end mb-3 mt-3">
                <div className="col-md-9 mt-3 mt-md-0 d-flex flex-column text-left">
                  {/* <label>Departamento</label>
                  <input className="textInput"></input> */}
                </div>
                <div className="col-md-3 mt-4 mt-md-0 text-left">
                  <div className=" btn-send mt-1 mt-md-5">
                      <button disabled={formSended}  className="send w-100 d-flex justify-content-between align-items-center" value="Enviar" onClick={submitProgramas}>
                        Enviar
                        <img src="/assets/arrow-next.svg" alt="arrow" className="arrow-cta"/>
                      </button>
                  </div>
                </div>
              </div>

              
            </div>
          </div>
        </div>
        
        {/* Inversiones */}
        <div class="collapse" id="inversiones">
          <div class={`card card-body px-0 ${collapsedElement !== 'inversiones' ? 'collapse' : ''}`}>
            <p className="title-contacto">Alquilá</p>
            <div className="col-12 px-0">
              <div className="row align-items-end">
                <div className="col-md-3 mt-3 mt-md-0 d-flex flex-column text-left">
                  <label>Organización</label>
                  <input className="textInput" value={inversionesOrganizacion} onChange={(e) => setinversionesOrganizacion(e.target.value)} required></input>
                </div>
                <div className="col-md-3 mt-3 mt-md-0 d-flex flex-column text-left">
                  <label>Teléfono</label>
                  <input className="textInput" value={inversionesTelefono} onChange={(e) => setinversionesTelefono(e.target.value)} required></input>
                </div>
                <div className="col-md-3 mt-4 mt-md-0 text-left">
                  <div className=" btn-send">
                      <button disabled={formSended} className="send w-100 d-flex justify-content-between align-items-center" value="Enviar" onClick={submitInversiones}>
                        Enviar
                        <img src="/assets/arrow-next.svg" alt="arrow" className="arrow-cta"/>
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Sobre Mani */}
        <div class="collapse" id="sobremani">
          <div class={`card card-body px-0 ${collapsedElement !== 'sobremani' ? 'collapse' : ''}`}>
          <p className="title-contacto">Estamos para ayudarte</p>
            <div className="col-12 px-0">
              <div className="row align-items-end">
                <div className="col-md-9 mt-3 mt-md-0 d-flex flex-column text-left">
                  <label>Mensaje</label>
                  <textarea value={sobremaniMessage} onChange={(e) => setSobremaniMessage(e.target.value)} className="textInput msj-sobremani" required></textarea>
                </div>
                <div className="col-md-3 mt-4 mt-md-0 text-left">
                  <div className=" btn-send">
                      <button disabled={formSended} className="send w-100 d-flex justify-content-between align-items-center" value="Enviar" onClick={submitSobremani}>
                        Enviar
                        <img src="/assets/arrow-next.svg" alt="arrow" className="arrow-cta"/>
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Sobre Maniseros */}
        <div class="collapse" id="sobremaniseros">
        <div class={`card card-body px-0 ${collapsedElement !== 'sobremaniseros' ? 'collapse' : ''}`}>
          <p className="title-contacto">Sobre Nosotros</p>
            <div className="col-12 px-0">
              <div className="row align-items-end">
                <div className="col-md-9 mt-3 mt-md-0 d-flex flex-column text-left">
                  <label>Mensaje</label>
                  <textarea value={sobremaniserosMessage} onChange={(e) => setsobremaniserosMessage(e.target.value)} required className="textInput msj-sobremani"></textarea>
                </div>
                <div className="col-md-3 mt-4 mt-md-0 text-left">
                  <div className="btn-send">
                      <button disabled={formSended} type="button" className="send w-100 d-flex justify-content-between align-items-center" onClick={submitSobremaniseros}>
                        Enviar
                      <img src="/assets/arrow-next.svg" alt="arrow" className="arrow-cta"/>
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      </div>
    );
  }
  
  export default DropdownContacto;
  