import React from 'react';
import Highlights from './Highlights'
import '../scss/ProgramaLlave.scss';

function ProgramaLlave() {
    return (
      <>
      <section className="Llave">
            <div className="position-relative py-5">
                <div className="bg-grey-llave"></div>
                <div className="container position-relative">
                    <img src="/assets/gold-fedea.svg" alt="deco" className="deco-fedeagold"/>
                    <div className="row">
                        <div className="col-md-6 section01">
                            <Highlights classNameH="interna" firstLineH=" Program" firstLine="a" secondLineH="Llav " secondLine="e en Mano"/>
                            <p className="destacadoLlave my-4 my-md-0">Una tentadora propuesta (“all included”) destinada a un grupo limitado de productores que quieren probar el cultivo por primera vez.</p>
                            <p className="infoLlave">El Programa Llave en Mano , una verdadera solución integral, será gerenciado en un 100% por PDM e incluye la oferta del servicio de dirección técnica y ejecución total del cultivo de maní, contemplando desde la elección del lote hasta la cosecha.</p>
                            <p className="infoLlave">Maniseros, junto al productor, ejecutará y llevará adelante el proceso integral del cultivo con todo su conocimiento y buenas prácticas, haciéndose cargo de la dirección técnica, la protección del cultivo, las labores y hasta la cosecha, momento en que PDM se cobrará por su participación en canje sobre lo producido, descontando el costo total del cultivo ejecutado.</p>
                        </div>
                        <div className="col-md-6">
                            <a target="_blank" href="https://www.google.com/maps/d/u/3/edit?mid=1UYS6M_6n85kULsvtXHUF0rbo1fmXsj0a&usp=sharing">
                            <img src="/assets/map-llave.png" alt="map" className="map"/>
                            </a>
                            <img src="/assets/sello-arg.svg" alt="arg" className="sello-arg" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="ventajas py-3 py-md-5">
                <div className="container">
                    <img src="/assets/big-gold.svg" alt="deco" className="deco-big-gold" />
                    <div className="gold-ventajas"></div>
                    <h3 className="title-compromise">Ventajas de Llave en Mano</h3>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="ventaja">
                                <p>El dueño del campo mantiene su rol de productor</p>
                            </div>
                            <div className="ventaja">
                                <p>No hay cargos de administración del cultivo</p>
                            </div>
                            <div className="ventaja">
                                <p>No hay componente financiero en el canje</p>
                            </div>
                            <div className="ventaja mb-0">
                                <p>Gestión digital de datos
                                    {/* <img src="/assets/asterisco.svg" className="asterisco" alt="asterisco"/> */}
                                    </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ventaja">
                                <p>Realización total del cultivo con la dirección técnica de PDM</p>
                            </div>
                            <div className="ventaja">
                                <p>Planificación competitiva del cultivo</p>
                            </div>
                            <div className="ventaja">
                                <p>Apalancamiento total del cultivo</p>
                            </div>
                            <div className="ventaja">
                                <p>Documentación total del cultivo y reportes quincenales</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </section>
      </>
    );
  }
  
  export default ProgramaLlave;
  