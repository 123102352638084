import React from 'react';
import Highlights from './Highlights'
import '../scss/HeroVideo.scss';

function HeroVideo() {
    return (
      <section className="HeroVideo">
        <video autoPlay loop muted  id="myVideo" playsInline>
            <source src="/assets/hero.mp4" type="video/mp4" fileConfig={{ attributes: { playsinline: true }}} autoPlay/>
        </video>
        <div className="titleHome">
            <div className="container">
              <div className="d-none d-md-flex align-items-end h-80">
                  <Highlights classNameH="homeH" firstLineH="MANÍ. LA EVOLUC" firstLine="IÓN" secondLineH="de nuestr"secondLine="o campo" onEnterViewport= { () => console.log('') }/>
              </div>
              <div className="d-flex d-md-none align-items-end h-80">
                  <Highlights classNameH="homeH" firstLineH="MANÍ" secondLineH="LA EVOLUCI"secondLine="ÓN" thirthLineH=" " thirthLine="de nuestro campo"/>
              </div>
            </div>
        </div>
       <img className="deco-01" src="/assets/deco-grey.svg" alt="deco"/>
      </section>
    );
  }
  
  export default HeroVideo;