import React from 'react';
import '../scss/ContactoProgramas.scss';
import {BrowserRouter as Router, HashRouter, Switch,Route,Link} from "react-router-dom";
import { useRecoilState } from 'recoil';
import { formState } from '../atom'

function ContactoProgramas() {
    const [form, setForm] = useRecoilState(formState)
    const onChange = event => setForm({...form, [event.target.name]: event.target.value})

    const {name, email, surname} = form

    return (
      <>
        <section className="ContactoProgramas">
            <div className="gold-rectangle-01"></div>
            <div className="gold-rectangle-02"></div>
            <img src="/assets/lineas_azules.svg" alt="lineas" className="lineas-azules" />
            <div className="container d-flex flex-column align-items-center">
                <Link to="/contacto" className="d-none d-md-block">
                <a href="#" className="botonPlan d-none d-md-block">
                    <div className="row d-flex align-items-center justify-content-center">
                        <p>¿TE INTERESA ALGÚN PLAN? HABLANOS</p>
                        <img src="/assets/arrow-next.svg" alt="arrow" className="arrow-nextprogram" />
                    </div>
                </a>
                </Link>
                <Link to="/contacto" className="botonPlan d-md-none">
                    <div className="row d-flex flex-column align-items-center justify-content-center">
                        <p>¿TE INTERESA ALGÚN PLAN?<br/></p>
                        <div className="row">
                            <p>HABLANOS</p>
                            <img src="/assets/arrow-next.svg" alt="arrow" className="arrow-nextprogram" />
                        </div>
                    </div>
                </Link>
                <div className="form-enviar">
                    <div className="gold-rectangle-03"></div>
                    <p className="epigrafe">Contacto</p>
                    <h4>El mejor maní del mundo se cultiva en nuestra tierra.</h4>
                    <form>
                        <div className="row m-0 justify-content-between">
                            <div className="inputlabel d-flex flex-column">
                                <label>Nombre</label>
                                <input name='name' value={name} onChange={onChange} className="textInput"></input>
                            </div>
                            <div className="inputlabel d-flex flex-column">
                                <label>Apellido</label>
                                <input name='surname' value={surname} onChange={onChange} className="textInput"></input>
                            </div>
                            <div className="inputlabel d-flex flex-column">
                                <label>Email</label>
                                <input name='email' value={email} onChange={onChange} className="textInput"></input>
                            </div>
                        </div>
                        <div className="col-10 d-flex justify-content-end" id="btn-contacto-vm">
                            <Link to="/contacto" className="submitBtn mt-4">
                                <div className="row justify-content-between px-3">
                                    <p className="pr-5">Enviar</p>
                                    <img src="/assets/arrow-next.svg" alt="arrow"/>
                                </div>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </section>
      </>
    );
  }
  
  export default ContactoProgramas;
  