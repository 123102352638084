import React from 'react';
import '../scss/ModuloProductoEtapa.scss';

function ModuloProductoEtapa(props) {
    return (
      <div className="ModuloProductoEtapa">
        <div className="col-12">
                    {props.titleEtapa && <p className="titleEtapa"> {props.titleEtapa} </p>}
            <div className="row">
                <div className="col-4 w-100 h-100 position-relative">
                    <div className="position-absolute tierra-aliados"><img src="/assets/tierra-aliados.svg"/></div>
                    {props.imgEtapa && <img src={props.imgEtapa} className="imgEtapa" alt={props.titleEtapa}/>}
                </div>
                <div className="col-8 d-flex px-0">
                  {props.infoEtapa && 
                  <div>
                    <p className="infoEtapa pl-md-1">{props.infoEtapa}</p>
                    <p className="infoEtapa pl-md-1">{props.infoEtapa2}</p>
                    <p className="infoEtapa pl-md-1">{props.infoEtapa3}</p>
                    <p className="infoEtapa pl-md-1">{props.infoEtapa4}</p>
                    <p className="infoEtapa pl-md-1">{props.infoEtapa5}</p>
                    <p className="infoEtapa pl-md-1">{props.infoEtapa6}</p>
                    </div>}

                  {props.claseImgEtapa7 && 
                  <div className="row">
                    <a href={props.href01} target="_blank" className="col-6 col-md-4 d-flex">
                      <img src={props.imgparter01} className={props.imgpartner} alt="Producto"/>
                    </a>
                    <a href={props.href02} target="_blank" className="col-6 col-md-4 d-flex">
                      <img src={props.imgparter02} className={props.imgpartner} alt="Producto"/>
                    </a>
                    <a href={props.href03} target="_blank" className="col-6 col-md-4 d-flex">
                      <img src={props.imgparter03} className={props.imgpartner} alt="Producto"/>
                    </a>
                    <a href={props.href04} target="_blank" className="col-6 col-md-4 d-flex">
                      <img src={props.imgparter04} className={props.imgpartner} alt="Producto"/>
                    </a>
                    <a href={props.href05} target="_blank" className="col-6 col-md-4 d-flex">
                      <img src={props.imgparter05} className={props.imgpartner} alt="Producto"/>
                    </a>
                    <a href={props.href06} target="_blank" className="col-6 col-md-4 d-flex">
                      <img src={props.imgparter06} className={props.imgpartner} alt="Producto"/>
                    </a>
                    <a href={props.href07} target="_blank" className="col-6 col-md-4 d-flex">
                      <img src={props.imgparter07} className={props.imgpartner} alt="Producto"/>
                    </a>
                  </div>
                  }

                  {props.claseImgEtapa6 && 
                  <div className="row">
                    <a href={props.href01} target="_blank" className="col-6 col-md-4 d-flex align-items-center">
                      <img src={props.imgparter01} className={props.imgpartner} alt="Producto"/>
                    </a>
                    <a href={props.href02} target="_blank" className="col-6 col-md-4 d-flex align-items-center">
                      <img src={props.imgparter02} className={props.imgpartner} alt="Producto"/>
                    </a>
                    <a href={props.href03} target="_blank" className="col-6 col-md-4 d-flex align-items-center">
                      <img src={props.imgparter03} className={props.imgpartnerp} alt="Producto"/>
                    </a>
                    <a href={props.href04} target="_blank" className="col-6 col-md-4 d-flex align-items-center">
                      <img src={props.imgparter04} className={props.imgpartner} alt="Producto"/>
                    </a>
                    <a href={props.href05} target="_blank" className="col-6 col-md-4 d-flex align-items-center">
                      <img src={props.imgparter05} className={props.imgpartner} alt="Producto"/>
                    </a>
                    <a href={props.href06} target="_blank" className="col-6 col-md-4 d-flex align-items-center">
                      <img src={props.imgparter06} className={props.imgpartner} alt="Producto"/>
                    </a>
                  </div>
                  }
                  {props.claseImgEtapa3v6 && 
                  <div className="row">
                    <a href={props.href01} target="_blank" className="col-md-6 d-flex">
                      <img src={props.imgparter01} className={props.imgpartner} alt="Producto"/>
                    </a>
                    <a href={props.href02} target="_blank" className="col-md-6 d-flex">
                      <img src={props.imgparter02} className={props.imgpartner} alt="Producto"/>
                    </a>
                    <a href={props.href03} target="_blank" className="col-md-6 d-flex">
                      <img src={props.imgparter03} className={props.imgpartner} alt="Producto"/>
                    </a>
                  </div>
                  }
                  {props.claseImgEtapa6siembra && 
                  <div className="row h-sp">
                    <a href={props.href01} target="_blank" className="col-md-4 d-flex">
                      <img src={props.imgparter01} className={props.imgpartner201} alt="Producto"/>
                    </a>
                    <a href={props.href02} target="_blank" className="col-md-6 d-flex">
                      <img src={props.imgparter02} className={props.imgpartner202} alt="Producto"/>
                    </a>
                    <a href={props.href03} target="_blank" className="col-md-4 d-flex">
                      <img src={props.imgparter03} className={props.imgpartner203} alt="Producto"/>
                    </a>
                    <a href={props.href04} target="_blank" className="col-md-6 d-flex">
                      <img src={props.imgparter04} className={props.imgpartner204} alt="Producto"/>
                    </a>
                    <a href={props.href05} target="_blank" className="col-md-4 d-flex">
                      <img src={props.imgparter05} className={props.imgpartner205} alt="Producto"/>
                    </a>
                    <a href={props.href06} target="_blank" className="col-md-6 d-flex">
                      <img src={props.imgparter06} className={props.imgpartner206} alt="Producto"/>
                    </a>
                  </div>
                  }
                  {props.claseImgEtapa32 && 
                  <div className="row">
                    <a href={props.href01} target="_blank" className="col-md-6 d-flex">
                      <img src={props.imgparter01} className={props.imgpartner} alt="Producto"/>
                    </a>
                    <a href={props.href02} target="_blank" className="col-md-6 d-flex">
                      <img src={props.imgparter02} className={props.imgpartner} alt="Producto"/>
                    </a>
                    <a href={props.href03} target="_blank" className="col-md-6 d-flex">
                      <img src={props.imgparter03} className={props.imgpartner} alt="Producto"/>
                    </a>
                  </div>
                  }
                  {props.claseImgEtapa4 && 
                  <div className="row">
                    <a href={props.href01} target="_blank" className="col-md-6 d-flex">
                      <img src={props.imgparter01} className={props.imgpartner1} alt="Producto"/>
                    </a>
                    <a href={props.href02} target="_blank" className="col-md-6 d-flex">
                      <img src={props.imgparter02} className={props.imgpartner2} alt="Producto"/>
                    </a>
                    <a href={props.href03} target="_blank" className="col-md-6 d-flex">
                      <img src={props.imgparter03} className={props.imgpartner3} alt="Producto"/>
                    </a>
                    <a href={props.href04} target="_blank" className="col-md-6 d-flex">
                      <img src={props.imgparter04} className={props.imgpartner4} alt="Producto"/>
                    </a>
                  </div>
                  }
                  {props.claseImgEtapa2 && 
                  <div className="row">
                    <a href={props.href01} target="_blank" className="col-md-6 d-flex">
                      <img src={props.imgparter01} className={props.imgpartner01} alt="Producto"/>
                    </a>
                    <a href={props.href02} target="_blank" className="col-md-6 d-flex">
                      <img src={props.imgparter02} className={props.imgpartner02} alt="Producto"/>
                    </a>
                  </div>
                  }
                  {props.claseImgEtapa212 && 
                  <div className="row">
                    <a href={props.href01} target="_blank" className="col-12 d-flex">
                      <img src={props.imgparter01} className={props.imgpartner} alt="Producto"/>
                    </a>
                    <a href={props.href02} target="_blank" className="col-12 d-flex">
                      <img src={props.imgparter02} className={props.imgpartner2} alt="Producto"/>
                    </a>
                  </div>
                  }
                  {props.claseImgEtapa12 && 
                  <div className="">
                    <a href={props.href01} target="_blank" className="d-flex">
                      <img src={props.imgparter01} className={props.imgpartner} alt="Producto"/>
                    </a>
                  </div>
                  }
                </div>
            </div>
        </div>
      </div>
    );
  }
  
  export default ModuloProductoEtapa;
  