import React, {useEffect} from 'react';
import HeroVideo from './HeroVideo.js'
import Highlights from './Highlights'
import ContactoHome from './ContactoHome'
import HomeAlianzas from './HomeAlianzas'
import RenderMani from './RenderMani'
import Footer from './Footer';
import {BrowserRouter as Router, HashRouter, Switch,Route,Link} from "react-router-dom"
import '../scss/bootstrap.scss';
import '../scss/Home.scss';






function Home() {

  useEffect(() => {
    var BABYLON = window.BABYLON;
    var canvas = document.getElementById('renderCanvas');
    var engine = new BABYLON.Engine(canvas, true);
    var campos = new BABYLON.Vector3(0, 0.4, 0.3)
    var rotationCoor = new BABYLON.Vector3(1, 1, 1);
  
    var createScene = function() {
      // Create a basic BJS Scene object.
      var scene = new BABYLON.Scene(engine);
      scene.clearColor = new BABYLON.Color3( 238, 222, 234);
      scene.ambientColor = new BABYLON.Color3.FromHexString("#eeeade");
      
      // Create a FreeCamera, and set its position to (x:0, y:5, z:-10).
      var camera = new BABYLON.ArcRotateCamera("camera1",  100, 0, 0, new BABYLON.Vector3(100, 100, 180), scene);
      camera.setPosition(new BABYLON.Vector3(-50, 230, 180));
      camera.attachControl(canvas, true);
  
      /************Creation of Pilot*********************************/
      // var pilot = BABYLON.MeshBuilder.CreateCylinder("pilot", {height:0.75, diameterTop:0.2, diameterBottom:0.5, tessellation:6, subdivisions:1} , scene);
      // var greyMat = new BABYLON.StandardMaterial("grey", scene);
      // greyMat.emissiveColor = new BABYLON.Color3(0.2,0.2,0.2);
      // pilot.material = greyMat;
      
      // var axis = new BABYLON.Vector3(0, 1, 0);
      // var drawAxis = BABYLON.MeshBuilder.CreateLines("vector", {
      // 	points:[
      // 		pilot.position.add(axis.scale(-5)), 
      // 		pilot.position.add(axis.scale(5)) 
      // 	 ]
      // }, scene);
  
      // Target the camera to scene origin.
      camera.setTarget(new BABYLON.Vector3(0, 200, 0));
  
      // Attach the camera to the canvas.
      camera.attachControl(canvas, true);
  
      // Create a basic light, aiming 0,1,0 - meaning, to the sky.
      var light = new BABYLON.HemisphericLight('light1', new BABYLON.Vector3(10,10,100), scene);
      
      var speed = 0.0005
  
      BABYLON.SceneLoader.ImportMesh("", "", "TEST_07.glb", scene, function (newMeshes, particleSystems, skeletons, animationGroups) {  
        newMeshes[0].scaling.scaleInPlace(450);
            setInterval(function(){
                newMeshes[0].rotate(new BABYLON.Vector3(0, 100, 0), speed, BABYLON.Space.WORLD)
            }, 10);
          // var animationDoor = new BABYLON.Animation("openDoor", "rotation.z", 30, BABYLON.Animation.ANIMATIONTYPE_FLOAT);
          // console.log('2', mesh)
          // mesh.animations.push(animationDoor);
          // console.log('3')
          // scene.beginAnimation(mesh, 0, 100, true);
        // canvas.addEventListener("pointerdown", onPointerDown, false);
    
        // scene.onDispose = function () {
        // 	canvas.removeEventListener("pointerdown", onPointerDown);
        // }
      }, () => console.log(''));
  
      /**************Animation of Rotation**********/
  
  
      // var angle = 0.1;
      // scene.registerAfterRender(function () { 
      // 	pilot.rotate(axis, angle, BABYLON.Space.WORLD)
      // });
  
      return scene;
    }
  
    var planta = createScene();
  
    
    engine.runRenderLoop(function() {
      planta.render();
    });
  
  }, []);



    return (<>
      <div className="Home">
        <HeroVideo/>
        <div className="container">
        </div>
        <div className="position-relative">
          <canvas id="renderCanvas"></canvas>
          {/* <RenderMani/> */}
          <section className="sectionsHome ciclosection ciclopruebauno">
            <div className="row justify-content-between w-100">
              <div className="col-12 col-md-7">
                <Link to="/sobremani">
                    <p>CICLO DE VIDA <img src="/assets/arrow-next-blue.svg" alt="arrow"/></p>
                    <Highlights classNameH="interna" firstLineH="MANÍ. TIERR" firstLine="A Y AGUA," secondLineH="el balance perfecto para log"secondLine="rar" thirthLineH="un mix sustenta"thirthLine="ble." />
                </Link>
              </div>
                <div className="col-md-1 d-none d-md-flex align-items-end">
                  <Link to="/sobremani">
                    <img src="/assets/homeCiclo.svg" alt="Ciclo"/>
                  </Link>
                </div>
            </div>
          </section>
          <section className="sectionsHome ciclosection ciclopruebados">
            <div className="row justify-content-between w-100">
              <div className="col-12 col-md-7">
                <Link to="/programas">
                    <p>PROGRAMA MANISEROS <img src="/assets/arrow-next-blue.svg" alt="arrow"/></p>
                    <Highlights classNameH="interna" firstLineH="UNA PROPUESTA SUPERA" firstLine="DORA" secondLineH="y 2 plan"secondLine="es para lograr el éxito." />
                </Link>
              </div>
                <div className="col-md-1 d-none d-md-flex align-items-end">
                  <Link to="/programas">
                    <img src="/assets/homeprogramas.svg" alt="Programas"/>
                  </Link>
                </div>
            </div>
          </section>


          <section className="sectionsHome ciclosection ciclopruebatres">
            <div className="row justify-content-between w-100">
              <div className="col-12 col-md-8">
                <Link to="/producto">
                    <p>PRODUCTO <img src="/assets/arrow-next-blue.svg" alt="arrow"/></p>
                    <Highlights classNameH="interna" firstLineH="UN CULTIVO QUE CRECE" firstLine="" secondLineH="en rendi"secondLine="miento y productividad."/>
                </Link>
              </div>
                <div className="col-md-1 d-none d-md-flex align-items-end">
                  <Link to="/producto">
                    <img src="/assets/homerProducto.svg" alt="Producto"/>
                  </Link>
                </div>
            </div>
          </section>
          
        </div>
        <HomeAlianzas/>
        <ContactoHome/>
      </div>
      <Footer/>
      </>
    );
  }
  
  export default Home;
  