import React from 'react';
import Highlights from './Highlights'
import ModuloProductoEtapa from './ModuloProductoEtapa'
import CarouselAliados from './CarouselAliados'
import Footer from './Footer';
import {BrowserRouter as Router, HashRouter, Switch,Route,Link} from "react-router-dom";
import '../scss/Aliados.scss';

function Basf() {
    return (
      <>
        <section className="aliado-Fedea">
        <div className="space"></div>
          <div className="Hero">
            <img className="banner-hero"  src="/assets/hero-bayer.png" alt="hero"/>
            <div className="info-banner">
              <div className="sectionclass">
                <p>Partners</p>
              </div>
              <div className="nombre-aliado">
                <img src="/assets/logo-bayer.png" alt="logo" className="logo-aliado"/>
                <Highlights classNameH="interna" firstLineH="# AliadosDeTuMan" firstLine="í"/>
              </div>
            </div>
          </div>
          <div className="descripccion py-5 mt-5 mt-md-0">
            <img src="/assets/deco-gold.svg" alt="deco" className="deco-descrips" />
              <div className="w-90">
            <div className="container">
              <div className="col-12 px-3 pl-md-0 d-flex align-items-center">
                <p className="title-presentacion mb-3 mb-md-0">Holding Global e <br/> Innovador</p>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <p className="text-aliado">Somos una empresa líder en la provisión de soluciones tecnológicas y productos agrícolas que mejoran la productividad agropecuaria y la calidad de los alimentos. Nuestro objetivo es permitir que pequeños y</p>
                </div>
                <div className="col-md-6">
                  <p className="text-aliado">grandes agricultores produzcan más en sus tierras y al mismo tiempo conserven los recursos naturales de nuestro planeta, tales como el agua y la energía.</p>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div className="servicios py-5">
            <div className="blue-servicio"></div>
            <div className="little-gold"></div>
            <div className="big-gold"></div>
            <div className="grey-servicios"></div>
            <img src="/assets/gold-wave.svg" alt="deco" className="wave-03" />
            <div className="container">
              <div className="grey-servicio"></div>
              <h3>Productos para cada etapa del crecimiento</h3>
              <div className="row py-3 mx-3 mx-md-0">
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="Pre - Emergencia" imgEtapa="../assets/planta0.jpg" claseImgEtapa6=" " 
                  imgparter01="/assets/bayer-01.png" href01="https://cropscience.bayer.com.ar/content/create-pro-semillero-soja"
                  imgparter02="/assets/bayer-02.png" href02="https://cropscience.bayer.com.ar/content/create-pro-semillero-soja"
                  imgparter03="/assets/bayer-03.png" href03="https://cropscience.bayer.com.ar/" imgpartnerp="peridiam"
                  imgparter04="/assets/bayer-04.png" href04="https://cropscience.bayer.com.ar/percutor-1"
                  imgparter05="/assets/bayer-05.png" href05="https://cropscience.bayer.com.ar/harness"
                  imgparter06="/assets/bayer-06.png" href06="https://cropscience.bayer.com.ar/roundup"/>
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa titleEtapa="V5 - R3" imgEtapa="../assets/planta6.jpg" claseImgEtapa6=" " 
                imgparter01="/assets/bayer-07.png" href01="https://cropscience.bayer.com.ar/latium-super"
                imgparter02="/assets/bayer-08.png" href02="https://cropscience.bayer.com.ar/content/clap-soja"
                imgparter03="/assets/bayer-09.png" href03="https://cropscience.bayer.com.ar/content/decis-forte-soja"
                imgparter04="/assets/bayer-10.png" href04="https://cropscience.bayer.com.ar/content/sphere-max-man%C3%AD"
                imgparter05="/assets/bayer-11.png" href05="https://cropscience.bayer.com.ar/cripton-man%C3%AD"
                imgparter06="/assets/bayer-12.png" href06="https://cropscience.bayer.com.ar/content/cripton-xpro-man%C3%AD"/>
                </div>
              </div>
            </div>
          </div>
          <div className="Beneficios py-5">
            <img src="/assets/gold-beneficios.svg" alt="deco" className="gold-beneficios"/>
            <div className="container">
              <h4 className="mb-4">Beneficios</h4>
              {/* <CarouselAliados/> */}
              <div className="row">
                <div className="col-md-4">
                  <a href="https://climatefieldview.com.ar/" target="_blank">
                    <div className="cuadro-container">
                      <div className="d-flex flex-column">
                        <img src="/assets/fieldview.png" alt="fieldview" className="field" />
                        <p>Climate FieldView<sup>tm </sup> interpreta los datos de tu campo de una forma simple para que puedas aprovechar al máximo cada una de tus hectáreas.</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4">
                  <a href="https://argentina.redagroservices.com/Account/LogOn?returnUrl=%2F" target="_blank">
                    <div className="cuadro-container">
                      <div className="d-flex flex-column">
                        <img src="/assets/agroservices.png" alt="multiplica"/>
                        <p>Una verdadera red de relacionamiento que potencia el desarrollo de cada integrante. Una propuesta que llega para sumar.</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4">
                  <div className="cuadro-container">
                    <div className="d-flex flex-column">
                    <p className="dest mb-3">Charlas con expertos</p>
                      <p>Soluciones de sustentabilidad con los programas Yo Aplico Responsablemente y Forward Farming Bayer. Charlas con referentes del control de malezas,enfermedades, aplicaciones y Buenas Prácticas Agrícolas [BPA]</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cta-aliados py-5">
            <div className="container">
              <div className="col-12">
                <div href="#" className="interesado-aliados">
                  <Link to="/contacto">
                    <div className="row justify-content-center align-items-center">
                      <p>SI ESTÁS INTERESADO COMUNICATE CON NOSOTROS</p>
                      <img src="/assets/arrow-next.svg" alt="arrow" className="arrow-cta" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer/>
      </>
    );
  }
  
  export default Basf;
  