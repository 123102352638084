import React from 'react';
import Highlights from './Highlights'
import ModuloProductoEtapa from './ModuloProductoEtapa'
import CarouselAliados from './CarouselAliados'
import Footer from './Footer';
import {BrowserRouter as Router, HashRouter, Switch,Route,Link} from "react-router-dom";
import '../scss/Aliados.scss';

function Basf() {
    return (
      <>
        <section className="aliado-Fedea">
        <div className="space"></div>
          <div className="Hero">
            <img className="banner-hero"  src="/assets/herp-oroverde.png" alt="hero"/>
            <div className="info-banner">
              <div className="sectionclass">
                <p>Partners</p>
              </div>
              <div className="nombre-aliado">
                <img src="/assets/logo-oro-verde.png" alt="logo" className="logo-aliado"/>
                <Highlights classNameH="interna" firstLineH="Vemos el camp" firstLine="o de" secondLineH="Otro colo"secondLine="r"/>
              </div>
            </div>
          </div>
          <div className="descripccion py-5 mt-5 mt-md-0">
            <img src="/assets/deco-gold.svg" alt="deco" className="deco-descrips" />
              <div className="w-90">
            <div className="container">
              <div className="col-12 px-3 pl-md-0 d-flex align-items-center">
                <p className="title-presentacion mb-3 mb-md-0">Nosotros</p>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <p className="text-aliado">Oro Verde Servicios es una empresa familiar dedicada a prestar servicios a la cadena agroalimentaria, fundamentalmente servicios de sanidad vegetal, orientados al desarrollo de tecnologías de procesos y</p>
                </div>
                <div className="col-md-6">
                  <p className="text-aliado">manejo integral de los cultivos, abarcando desde la sanidad y calidad de la semilla al monitoreo de cultivos brindando información para la toma de decisiones; disminuyendo el riesgo y la incertidumbre. </p>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div className="servicios py-5">
            <div className="blue-servicio"></div>
            <div className="little-gold"></div>
            <div className="big-gold"></div>
            <div className="grey-servicios"></div>
            <img src="/assets/gold-wave.svg" alt="deco" className="wave-03" />
            <div className="container">
              <div className="grey-servicio"></div>
              <h3>Servicios para cada etapa del crecimiento</h3>
              <div className="row py-3 mx-3 mx-md-0">
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="Pre siembra" imgEtapa="../assets/planta00.jpg" infoEtapa="- Elección del lote." infoEtapa2="- Análisis de patógenos de suelo." infoEtapa3="- Análisis de semillas."infoEtapa4="- Elección de variedades."infoEtapa5="- Asesoramiento en Barbechos."/>
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="Pre Siembra" imgEtapa="../assets/planta00.jpg" infoEtapa="- Asesoramiento en Barbechos. "infoEtapa2="- Calidad de implantación y emergencia."/>
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="Ve - V0" imgEtapa="../assets/planta4.jpg" infoEtapa="- Control de malezas post-emergencia (recomendación)"/>
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="V1 - Vn" imgEtapa="../assets/planta4.jpg" infoEtapa="- Monitoreo de viruela." infoEtapa2="- Diagnósticos de enfermedades." infoEtapa3="- Diagnósticos de plagas."infoEtapa4="- Visitas técnicas o recorrido ."infoEtapa5="- Técnico de lotes." infoEtapa6="- Sistema de alerta de viruela."/>
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="R1 - R6" imgEtapa="../assets/planta6.jpg" infoEtapa="- Monitoreo de viruela."infoEtapa2="- Diagnósticos de enfermedades." infoEtapa3="- Diagnósticos de plagas."infoEtapa4=" - Visitas técnicas o recorrido."infoEtapa5="- Técnico de lotes."infoEtapa6="- Sistema de alerta de viruela."/>
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="R7" imgEtapa="../assets/planta12.jpg" infoEtapa="- Sistesma de alerta de viruela."/>
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="Cosecha" imgEtapa="../assets/planta13.jpg" infoEtapa="- Calidad de semilla"/>
                </div>
              </div>
            </div>
          </div>
          <div className="cta-aliados py-5">
            <div className="container">
              <div className="col-12">
                <div href="#" className="interesado-aliados">
                  <Link to="/contacto">
                    <div className="row justify-content-center align-items-center">
                      <p>SI ESTÁS INTERESADO COMUNICATE CON NOSOTROS</p>
                      <img src="/assets/arrow-next.svg" alt="arrow" className="arrow-cta" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer/>
      </>
    );
  }
  
  export default Basf;
  