import React from 'react';
import Highlights from './Highlights'
import {BrowserRouter as Router, HashRouter, Switch,Route,Link} from "react-router-dom"
import '../scss/HomeAlianzas.scss';

function HomeAlianzas() {
    return (
      <>
      <section className="homeAlianzas py-5">
          <div className="background"></div>
          <img src="/assets/deco-gold.svg" className="bg-img" alt="fondo"/>
          <div className="container">
            <p className="epigrafe-contacto">ALIANZAS PARA CRECER</p>
            <div className="d-none d-md-flex titlealianzas">
                <Highlights classNameH="interna" firstLineH="LA OPORTUNIDAD DE TRA" firstLine="NSITAR" secondLineH="EL CAMI" secondLine="NO junto a los mejores."/>
            </div>
            <div className="d-flex d-md-none titlealianzas mb-5">
                <Highlights classNameH="interna" firstLineH="LA OPORTUNIDA" firstLine="D DE" secondLineH="TRANSITAR E" secondLine="L CAMINO" thirthLineH="junto a los mejo" thirthLine="res."/>
            </div>
            <div className="row">
                <div className="col-6 col-md-3 mb-4">
                    <div className="alianza fedea-alianza">
                        <img src="/assets/logo-fedea.svg" alyt="Fedea" />
                        <Link to="/fedea">
                            <a className="btn-alianza-fedea row">
                                <p>VER MÁS</p>
                                <img src="/assets/arrow-next.svg" alt="arrow" className="arrow-next" />
                            </a>
                        </Link>
                    </div>
                </div>
                <div className="col-6 col-md-3 mb-4">
                    <div className="alianza">
                        <img src="/assets/logo-basf.png" alyt="Fedea" />
                        <Link to="/basf">
                            <a className="btn-alianza row">
                                <p>VER MÁS</p>
                                <img src="/assets/arrow-next.svg" alt="arrow" className="arrow-next" />
                            </a>
                        </Link>
                    </div>
                </div>
                <div className="col-6 col-md-3 mb-4">
                    <div className="alianza">
                        <img src="/assets/logo-bayer.png" alyt="Fedea" />
                        <Link to="/bayer">
                            <a className="btn-alianza row">
                                <p>VER MÁS</p>
                                <img src="/assets/arrow-next.svg" alt="arrow" className="arrow-next" />
                            </a>
                        </Link>
                    </div>
                </div>
                <div className="col-6 col-md-3 mb-4">
                    <div className="alianza">
                        <img src="/assets/logo-corteva.png" alyt="Fedea" />
                        <Link to="/corteva">
                            <a className="btn-alianza row">
                                <p>VER MÁS</p>
                                <img src="/assets/arrow-next.svg" alt="arrow" className="arrow-next" />
                            </a>
                        </Link>
                    </div>
                </div>
                <div className="col-6 col-md-3 mb-4 mb-md-0">
                    <div className="alianza">
                        <img src="/assets/logo-nova.png" alyt="Fedea" />
                        <Link to="/nova">
                            <a className="btn-alianza row">
                                <p>VER MÁS</p>
                                <img src="/assets/arrow-next.svg" alt="arrow" className="arrow-next" />
                            </a>
                        </Link>
                    </div>
                </div>
                <div className="col-6 col-md-3 mb-4 mb-md-0">
                    <div className="alianza">
                        <img src="/assets/logo-summit.svg" alyt="Fedea" />
                        <Link to="/summit">
                            <a className="btn-alianza row">
                                <p>VER MÁS</p>
                                <img src="/assets/arrow-next.svg" alt="arrow" className="arrow-next" />
                            </a>
                        </Link>
                    </div>
                </div>
                <div className="col-6 col-md-3 mb-4 mb-md-0">
                    <div className="alianza">
                        <img src="/assets/logo-syngenta.svg" alyt="Fedea" />
                        <Link to="/syngenta">
                            <a className="btn-alianza row">
                                <p>VER MÁS</p>
                                <img src="/assets/arrow-next.svg" alt="arrow" className="arrow-next" />
                            </a>
                        </Link>
                    </div>
                </div>
            </div>
          </div>
      </section>
        
      </>
    );
  }
  
  export default HomeAlianzas;
  