import React from 'react';
import Highlights from './Highlights'
import ModuloProductoEtapa from './ModuloProductoEtapa'
import CarouselAliados from './CarouselAliados'
import Footer from './Footer';
import {BrowserRouter as Router, HashRouter, Switch,Route,Link} from "react-router-dom";
import '../scss/Aliados.scss';

function Basf() {
    return (
      <>
        <section className="aliado-Fedea">
        <div className="space"></div>
          <div className="Hero">
            <img className="banner-hero"  src="/assets/hero-summit.png" alt="hero"/>
            <div className="info-banner">
              <div className="sectionclass">
                <p>Partners</p>
              </div>
              <div className="nombre-aliado">
                <img src="/assets/logo-summit.svg" alt="logo" className="logo-aliado"/>
                <Highlights classNameH="interna" firstLineH="Tecnologí" firstLine="a" secondLineH="japonesa líd"secondLine="er"/>
              </div>
            </div>
          </div>
          <div className="descripccion py-5 mt-5 mt-md-0">
            <img src="/assets/deco-gold.svg" alt="deco" className="deco-descrips" />
              <div className="w-90">
            <div className="container">
              <div className="col-12 px-3 pl-md-0 d-flex align-items-center">
                <p className="title-presentacion mb-3 mb-md-0">Una nueva forma de concebir la<br/>protección de nuestros cultivos</p>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <p className="text-aliado">La evolución comienza con el cambio y el cambio necesita decisión. El mayor desafío emergente implica desarrollar productos para la protección de cultivos cada vez más efectivos, pero que a su vez protejan debidamente a quienes los aplican, a quienes los consumen y al medio ambiente. Por esa razón Summit Agro es un partner destacado de Maniseros para su red de productores asociados, por su fuerte compromiso con el medio ambiente y con la calidad de vida de</p>
                </div>
                <div className="col-md-6">
                  <p className="text-aliado">las personas. Las actividades internas y externas desarrolladas por Summit Agro están dentro de un contexto sustentable, aplicando medidas concretas en pos del objetivo, brindando soluciones confiables frente a las necesidades y oportunidades de la comunidad agrícola y su sostenible crecimiento.</p>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div className="servicios py-5">
            <div className="blue-servicio"></div>
            <div className="little-gold"></div>
            <div className="big-gold"></div>
            <div className="grey-servicios"></div>
            <img src="/assets/gold-wave.svg" alt="deco" className="wave-03" />
            <div className="container">
              <div className="grey-servicio"></div>
              <h3>Productos para cada etapa del crecimiento</h3>
              <div className="row py-3 mx-3 mx-md-0">
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="Pre Siembra" imgEtapa="../assets/planta00.jpg" claseImgEtapa12=" " 
                  imgparter01="/assets/summit-01.png" href01="https://www.summitagro.com.ar/producto.php?id=18"/>
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="Pre emergencia" imgEtapa="../assets/planta0.jpg" claseImgEtapa12=" " 
                  imgparter01="/assets/summit-02.png" href01="https://www.summitagro.com.ar/producto.php?id=20" imgpartner="summit-metolacloros"
                  />
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="V1 - Vn" imgEtapa="../assets/planta4.jpg" claseImgEtapa12=" " 
                  imgparter01="/assets/summit-03.png" href01="https://www.summitagro.com.ar/producto.php?id=24"/>
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="R1 - R6" imgEtapa="../assets/planta6.jpg" claseImgEtapa12=" " 
                  imgparter01="/assets/summit-04.png" href01="https://www.summitagro.com.ar/producto.php?id=44"/>
                </div>
              </div>
            </div>
          </div>

          <div className="Beneficios py-5 d-md-flex">
            <img src="/assets/gold-beneficios.svg" alt="deco" className="gold-beneficios"/>
            <div className="container">
              <h4 className="mb-4">Beneficios</h4>
              <div className="row">
                <div className="col-md-4">
                  <a href="http://wa.me/5493512059489" target="_blank">
                    <div className="cuadro-container">
                      <div className="d-flex flex-column">
                        <img src="/assets/linea-wpp.png" alt="multiplica"/>
                        <p>Ahora estamos más cerca, hablemos por whatsapp</p>
                        <p className="dest">+54 9 3512 05-9489</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>


          <div className="cta-aliados py-5">
            <div className="container">
              <div className="col-12">
                <div href="#" className="interesado-aliados">
                  <Link to="/contacto">
                    <div className="row justify-content-center align-items-center">
                      <p>SI ESTÁS INTERESADO COMUNICATE CON NOSOTROS</p>
                      <img src="/assets/arrow-next.svg" alt="arrow" className="arrow-cta" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer/>
      </>
    );
  }
  
  export default Basf;
  