import React from 'react';
import Highlights from './Highlights'
import DropdownProgramas from './DropdownProgramas'
import DropdownProducto from './DropdownProducto'
import '../scss/ProgramaFedea.scss';

function ProgramaFedea() {
    return (
        <section className="Fedea" >
            <div className="position-relative pt-5">
                <div className="bg-blue-fedea"></div>
                <div className="container position-relative">
                    <img src="/assets/gold-fedea.svg" alt="deco" className="deco-fedeagold"/>
                    <div className="row">
                        <div className="col-md-6 section01">
                            <Highlights classNameH="homeH" firstLine="Program" firstLineH2="a Fedea" secondLine="&" secondLineH2="Prode" secondLineW="man"/>
                            <p className="infofedea my-5 my-md-0">FEDEA y PDM participan conjuntamente con Maniseros en la elaboración e implementación de este innovador programa que acompaña al productor desde la dirección técnica integral, hasta la cosecha final, brindándole soporte en cada etapa y las garantías necesarias para que pueda incorporar el maní llevando adelante una estrategia exitosa en su mix de cultivos mejorando su productividad.</p>
                        </div>
                        <div className="col-md-6">
                            <a target="_blank" href="https://www.google.com/maps/d/u/0/viewer?mid=1dFfh8oELqfRfMqBOTMdTgTWqQZqrw3_J&ll=-32.47804922591111%2C-61.861132999999995&z=6">
                                <img src="/assets/map.png" alt="map" className="map"/>
                            </a>
                            <img src="/assets/sello-01.svg" alt="arg" className="sello-arg" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="compromiso position-relative pb-5">
                <div className="container">
                <h3 className="title-compromise">Compromisos con el Productor</h3>
                <div className="row">
                    <div className="col-md-6 my-2">
                        <DropdownProgramas titleDropPrograms="1. DIRECCIÓN TÉCNICA INTEGRAL DEL CULTIVO" dataTarget="direccion" infoInterior="A cargo de un staff de expertos agrónomos maniseros, quienes le brindarán la DT integral del cultivo desde la elección del lote, preparación del suelo, protección del cultivo, y la decisión del momento y formas para con las mejores prácticas de manejo del cultivo."/>
                    </div>
                    <div className="col-md-6 my-2">
                        <DropdownProgramas titleDropPrograms="2. PROVISIÓN DE LAS MEJORES SEMILLAS" dataTarget="provision" infoInterior="Las cuales han sido tratadas con Azoxistrobina 0.6%, Carboxin 20%, Metiltiofanato 10%, Metalaxil 1.33% y Molibdeno (Mo) 4%, recibiendo un tratamiento único. Que garantizarán el éxito de la cosecha."/>
                    </div>
                    <div className="col-md-6 my-2">
                        <DropdownProgramas titleDropPrograms="3. ORGANIZACIÓN DE LA LOGÍSTICA DEL CULTIVO" dataTarget="organizacion" infoInterior="Los mejores colaboradores en cada proceso de la producción, mediante nuestra red de contratistas experimentados, para llegar en tiempo y forma a cumplimentar cada una de las labores que demanda el cultivo de maní."/>
                    </div>
                    <div className="col-md-6 my-2">
                        <DropdownProgramas titleDropPrograms="4. CAPACITACIÓN INTEGRAL" dataTarget="capacitaacion" infoInterior="A través de la cual los productores recibirán todo el conocimiento y les iremos contagiando nuestra pasión, capacitándolos sobre la forma correcta de hacer productivo el cultivo de maní. Viviendo una experiencia que los llevará a lograr una cosecha verdaderamente exitosa."/>
                    </div>
                    <div className="col-md-6 my-2">
                        <DropdownProgramas titleDropPrograms="5. COMPRA TOTAL GARANTIZADA" dataTarget="compra" infoInterior="Asegurando de esta manera el éxito sobre la producción obtenida de cada lote sembrado con maní, al valor de mercado de referencia y organizando la recepción total con mínimos contratiempos de mercadería."/>
                    </div>
                    
                </div>
                </div>
                <img src="/assets/big-gold.svg" alt="deco" className="deco-big-gold" />
            </div>
            <div className="beneficios py-5">
            <div className="bg-blue-light"></div>
            <img src="/assets/wave-gold.svg" alt="wave gold" className="wave-beneficios-01" />
            <img src="/assets/wave-gold.svg" alt="wave gold" className="wave-beneficios-02" />
            <img src="/assets/sello-mapa.svg" alt="sello" className="sello-mapa"/>
                <div className="container">
                    <h3 className>Beneficios para el productor</h3>
                    <div className="row justify-content-between">
                        <div className="col-md-4">
                            <div className="beneficio m-md-1">
                                <p className="title-beneficio">Garantía de cosecha</p>
                                <p className="text-beneficio">Bonificación total de la Cosecha ante un rendimiento inferior al Base referenciado en caja limpio y seco.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="beneficio m-md-1">
                                <p className="title-beneficio">Garantía de semillas</p>
                                <p className="text-beneficio">Bonificación parcial de la Semilla para la campaña siguiente ante un rendimiento inferior al Base referenciado en caja limpio y seco.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="beneficio m-md-1">
                                <p className="title-beneficio">Fidelidad Contratistas</p>
                                <p className="text-beneficio">Plus de tarifa para los contratistas avocados a las tareas de cosecha en productores bajo los programas de maní.</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
    );
  }
  
  export default ProgramaFedea;
  