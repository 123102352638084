import React from 'react';
import '../scss/ExitoProgramas.scss';

function ExitoProgramas() {
    return (
      <>
      <section className="Exito py-5">
          <div className="grey-bg-exito"></div>
          <div className="container">
              <h3>Asegurando el éxito de los cultivos bajo el siguiente programa</h3>
                <div className="row">
                    <div className="col-md-6">
                        <div className="clave flex-column">
                            <div className="d-flex align-items-center justify-content-start pb-2">
                                <img src="/assets/soporte.svg"/>
                                <p className="exitoTitle">SOPORTE</p>
                            </div>
                            <p>
                                Un equipo multidisciplinario capacitado, con 40 años de experiencia.
                            </p>
                        </div>
                        <div className="clave flex-column">
                            <div className="d-flex align-items-center justify-content-start pb-2">
                                <img src="/assets/labores.svg"/>
                                <p className="exitoTitle">LABORES</p>
                            </div>
                            <p>
                                La red de contratistas y maquinaria más grande y eficiente del país.
                            </p>
                        </div>
                        <div className="clave flex-column">
                            <div className="d-flex align-items-center justify-content-start pb-2">
                                <img src="/assets/semilla.svg"/>
                                <p className="exitoTitle">SEMILLA</p>
                            </div>
                            <p>
                                Nuestro mejor socio para cada uno de tus lotes y para el éxito de la cosecha.
                            </p>
                        </div>
                        <div className="clave flex-column">
                            <div className="d-flex align-items-center justify-content-start pb-2">
                                <img src="/assets/proceso.svg"/>
                                <p className="exitoTitle">Proceso y logística</p>
                            </div>
                            <p>
                                Recepción, análisis , procesamiento y acopio monitoreado con sistemas de trazabilidad digital.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img src="/assets/mani-exito.png" alt="mani" className="img-mani" />
                        <img src="/assets/sello-mapa.svg" alt="sello" className="sello-mapa"/>
                        <img src="/assets/wave-vertical.svg" alt="sello" className="wave-vertical"/>
                    </div>
                </div>
          </div>
      </section>
        
      </>
    );
  }
  
  export default ExitoProgramas;
  