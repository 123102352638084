import React from 'react';
import Video from './Video';
import {useParams} from "react-router-dom";
import sobremani from './sobremani.json'
import '../scss/SobreMani.scss';

function SobreMani() {
  const {id} = useParams()
  
  const cicloState = (sobremani[2]);
    return (
      <>
        <div className="SobreMani">
          <div className="space"></div>
          <div className="position-relative">
            <div className="wave-01">
              <img src="/assets/wave-gold.svg" alt="wave"/>
            </div>
            <div className="wave-02">
              <img src="/assets/wave-gold.svg" alt="wave"/>
            </div>
          </div>
          <div className="video">
              <Video/>
          </div>
          <div className="container">
              <p className="nameSection">Ciclo de Vida</p>
          </div>
        </div>
      </>
    );
  }
  
  export default SobreMani;