import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { hydrate, render } from "react-dom";

import {
  BrowserRouter as Router, HashRouter,
  Switch,
  Route,
  Link
} from "react-router-dom";

const Index = (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(Index, rootElement);
} else {
  render(Index, rootElement);
}
reportWebVitals();
