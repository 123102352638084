import React from 'react';
import Highlights from './Highlights'
import ModuloProductoEtapa from './ModuloProductoEtapa'
import CarouselAliados from './CarouselAliados'
import Footer from './Footer';
import {BrowserRouter as Router, HashRouter, Switch,Route,Link} from "react-router-dom";
import '../scss/Aliados.scss';

function Basf() {
    return (
      <>
        <section className="aliado-Fedea">
        <div className="space"></div>
          <div className="Hero">
            <img className="banner-hero"  src="/assets/hero-syngenta.png" alt="hero"/>
            <div className="info-banner">
              <div className="sectionclass">
                <p>Partners</p>
              </div>
              <div className="nombre-aliado">
                <img src="/assets/logo-syngenta.svg" alt="logo" className="logo-aliado"/>
              </div>
            </div>
          </div>
          <div className="descripccion py-5 mt-5 mt-md-0">
            <img src="/assets/deco-gold.svg" alt="deco" className="deco-descrips" />
              <div className="w-90">
            <div className="container">
              <div className="col-12 px-3 pl-md-0 d-flex align-items-center">
                <p className="title-presentacion mb-3 mb-md-0">Acerca de</p>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <p className="text-aliado">Syngenta es una de las principales empresas agrícolas del mundo. Nuestra ambición es ayudar a alimentar al mundo de manera segura mientras cuidamos el planeta. Nuestro objetivo es mejorar la sustentabilidad, la calidad y la seguridad de la agricultura con ciencia de clase mundial y soluciones innovadoras para cultivos. Nuestras tecnologías permiten a millones de agricultores de todo el mundo hacer un mejor uso de los recursos agrícolas limitados. Syngenta es parte del Syngenta Group con 48.000 personas en más de 100 países</p>
                </div>
                <div className="col-md-6">
                  <p className="text-aliado">y está trabajando para transformar como se producen los cultivos. A través de asociaciones, la colaboración y el programa The Good Growth Plan, estamos comprometidos en acelerar la innovación para los agricultores y la naturaleza, esforzándonos por una agricultura neutra en carbono, ayudando a las personas a mantenerse seguras y saludables y asociándonos para lograr un impacto.</p>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div className="servicios py-5">
            <div className="blue-servicio"></div>
            <div className="little-gold"></div>
            <div className="big-gold"></div>
            <div className="grey-servicios"></div>
            <img src="/assets/gold-wave.svg" alt="deco" className="wave-03" />
            <div className="container">
              <div className="grey-servicio"></div>
              <h3>Productos para cada etapa del crecimiento</h3>
              <div className="row py-3 mx-3 mx-md-0">
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="Pre siembra" imgEtapa="../assets/planta00.jpg" claseImgEtapa12=" " imgparter01="/assets/syngenta-01.png"/>
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="Pre Emergencia" imgEtapa="../assets/planta0.jpg" claseImgEtapa12=" " imgparter01="/assets/syngenta-02.png"/>
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="Ve - V0" imgEtapa="../assets/planta2.jpg" claseImgEtapa12=" " imgparter01="/assets/syngenta-03.png"/>
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="V1 - Vn" imgEtapa="../assets/planta4.jpg" claseImgEtapa12=" " imgparter01="/assets/syngenta-04.png"/>
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="R1 - R6" imgEtapa="../assets/planta6.jpg" claseImgEtapa12=" " imgparter01="/assets/syngenta-05.png"/>
                </div>
              </div>
            </div>
          </div>
          <div className="cta-aliados py-5">
            <div className="container">
              <div className="col-12">
                <div href="#" className="interesado-aliados">
                  <Link to="/contacto">
                    <div className="row justify-content-center align-items-center">
                      <p>SI ESTÁS INTERESADO COMUNICATE CON NOSOTROS</p>
                      <img src="/assets/arrow-next.svg" alt="arrow" className="arrow-cta" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer/>
      </>
    );
  }
  
  export default Basf;
  