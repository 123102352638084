import React from 'react';
import Highlights from './Highlights'
import ModuloProductoEtapa from './ModuloProductoEtapa'
import CarouselAliados from './CarouselAliados'
import Footer from './Footer';
import {BrowserRouter as Router, HashRouter, Switch,Route,Link} from "react-router-dom";
import '../scss/Aliados.scss';

function Fedea() {
    return (
      <>
        <section className="aliado-Fedea">
        <div className="space"></div>
          <div className="Hero">
            <img className="banner-hero"  src="/assets/hero-fedea.png" alt="hero"/>
            <div className="info-banner">
                <div className="sectionclass">
                  <p>Partners</p>
                </div>
                <div className="nombre-aliado">
                  <img src="/assets/logo-fedea.svg" alt="logo" className="logo-aliado"/>
                  <Highlights classNameH="interna" firstLineH="Calidad en el ser " firstLine="vicio" secondLineH="al client"secondLine="e"/>
                </div>
            </div>
          </div>
          <div className="descripccion py-5 mt-5 mt-md-0">
            <img src="/assets/deco-gold.svg" alt="deco" className="deco-descrips" />
              <div className="w-90">
            <div className="container">
              <div className="row px-3">
                <div className="col-32 px-0">
                  <img src="/assets/logo-fedea-prodeman.png" className="logo-aliado"/>
                </div>
                <div className="col-12 col-md-6 px-0 pl-md-3 d-flex align-items-center">
                  <p className="title-presentacion mb-3 mb-md-0">Fedea te acompaña<br/>todo el camino</p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <p className="text-aliado">Somos una empresa con más de 25 años de experiencia en el mercado agropecuario. Con 13 sucursales en La Pampa, oeste de Buenos Aires y sur de Córdoba acompañamos a los productores a lo largo de todo el ciclo productivo. Sumado al Programa Maní Fedea Prodeman tenemos otras 6 unidades de negocio: protección de cultivos, semillas, fertilizantes, acopio de granos, combustibles y nutrición animal.</p>
                </div>
                <div className="col-md-6">
                  <p className="text-aliado">Con un equipo de más de 170 personas brindamos asesoramiento técnico, comercial, servicios financieros, logística integral a campo, entre otros servicios. Además contamos con plantas de fertilizantes, acopio de granos y combustibles que nos permite estar cerca de nuestros clientes en las necesidades de su día a día.</p>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div className="servicios py-5">
            <div className="blue-servicio"></div>
            <div className="little-gold"></div>
            <div className="big-gold"></div>
            <div className="grey-servicios"></div>
            <img src="/assets/gold-wave.svg" alt="deco" className="wave-03" />
            <div className="container">
              <div className="grey-servicio"></div>
              <h3>Servicios para cada etapa del crecimiento</h3>
              <div className="row py-3 mx-3 mx-md-0">
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="Pre siembra" imgEtapa="../assets/planta00.jpg" infoEtapa="- Elección del lote  " infoEtapa2="- Planificación del barbecho" infoEtapa3="- Muestreo de Suelo " infoEtapa4="- Estrategia genética y fecha de siembra"/>
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="Pre emergencia" imgEtapa="../assets/planta0.jpg" infoEtapa="- Estrategias pre emergentes " infoEtapa2="- Definición de Siembra "infoEtapa3="- Logística de siembra " infoEtapa4="- Logística de semilla"/>
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="Ve V0" imgEtapa="../assets/planta4.jpg" infoEtapa="- Informe de implantación " infoEtapa2="- Definición de stand " infoEtapa3="- Evaluación inicial"/>
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="V1 - Vn - R1 - R6 - R7" imgEtapa="../assets/planta6.jpg" infoEtapa="- Monitoreo intensivo  " infoEtapa2="- Asesoramiento técnico" infoEtapa3="- Logística de labores" infoEtapa4="- Recomendaciones Fenotipado"/>
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="Cosecha" imgEtapa="../assets/planta13.jpg" infoEtapa="- Logística de cosecha  " infoEtapa2="- Soporte Informe de Pos Cosecha" infoEtapa3="- Contribución del cultivo Rotación y/o secuencia."/>
                </div>
              </div>
            </div>
          </div>
          <div className="fedea-ofrece">
            <img src="/assets/gold-wave.svg" alt="deco" className="wave-01" />
            <img src="/assets/gold-wave.svg" alt="deco" className="wave-02" />
            <div className="grey-bottom"></div>
            <div className="light-blue"></div>
            <div className="container">
              <h3 className="mb-4">Fedea te ofrece</h3>
              <div className="row">
                <div className="col-md-4 mb-4">
                  <div className="ofrece">
                    <img src="/assets/1.svg" alt="1" className="number" />
                    <p className="text">Mejora la rentabilidad de tu sistema productivo</p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="ofrece">
                    <img src="/assets/2.svg" alt="1" className="number" />
                    <p className="text">Gestión de labores específicas</p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="ofrece">
                    <img src="/assets/3.svg" alt="1" className="number" />
                    <p className="text">Asesoramiento y asistencia técnica especializada</p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="ofrece">
                    <img src="/assets/4.svg" alt="1" className="number" />
                    <p className="text">Respaldo de empresas sólidas y confiables</p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="ofrece">
                    <img src="/assets/5.svg" alt="1" className="number" />
                    <p className="text">Incorporación de un nuevo cultivo a la rotación</p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="ofrece">
                    <img src="/assets/6.svg" alt="1" className="number" />
                    <p className="text">Red de Soporte</p>
                    <a target="_blank" href="https://www.google.com/maps/d/edit?mid=1aicebE-iSv600djNiN1vSLGapa15WXJb&usp=sharing"><b>Ver mapa</b></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Beneficios py-5">
            <img src="/assets/gold-beneficios.svg" alt="deco" className="gold-beneficios"/>
            <div className="container">
              <h4 className="mb-4">Beneficios</h4>
              <CarouselAliados/>
            </div>
          </div>
          <div className="cta-aliados py-5">
            <div className="container">
              <div className="col-12">
                <div href="#" className="interesado-aliados">
                  <Link to="/contacto">
                    <div className="row justify-content-center align-items-center">
                      <p>SI ESTÁS INTERESADO COMUNICATE CON NOSOTROS</p>
                      <img src="/assets/arrow-next.svg" alt="arrow" className="arrow-cta" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer/>
      </>
    );
  }
  
  export default Fedea;
  