import React from 'react';
import Highlights from './Highlights'
import ModuloProductoEtapa from './ModuloProductoEtapa'
import CarouselAliados from './CarouselAliados'
import Footer from './Footer';
import {BrowserRouter as Router, HashRouter, Switch,Route,Link} from "react-router-dom";
import '../scss/Aliados.scss';

function Basf() {
    return (
      <>
        <section className="aliado-Fedea">
        <div className="space"></div>
          <div className="Hero">
            <img className="banner-hero" src="assets/hero-basf.png" alt="hero"/>
            <div className="info-banner">
              <div className="sectionclass">
                <p>Partners</p>
              </div>
              <div className="nombre-aliado">
                <img src="/assets/logo-basf.png" alt="logo" className="logo-aliado basf"/>
              </div>
            </div>
          </div>
          <div className="descripccion py-5 mt-5 mt-md-0">
            <img src="/assets/deco-gold.svg" alt="deco" className="deco-descrips" />
              <div className="w-90">
            <div className="container">
              <div className="col-12 px-3 pl-md-0 d-flex align-items-center">
                <p className="title-presentacion mb-3 mb-md-0 pb-3">Buscando juntos el equilibrio ideal para el éxito de los agricultores, la agricultura y las generaciones futuras</p>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <p className="text-aliado">La agricultura tiene que ver con el equilibrio. El equilibrio natural que impulsa las campañas, el clima y los ciclos de cultivo. Nunca ha habido un momento más desafiante en la agricultura. Pero tampoco nunca ha habido un momento de mayor oportunidad. BASF, a través de su experiencia mundial sabe lo importante que es conseguir un equilibrio ideal para el éxito de la agricultura. Por eso Maniseros cuenta con BASF como partner destacado para su red de productores</p>
                </div>
                <div className="col-md-6">
                  <p className="text-aliado">asociados, en este mundo dinámico, BASF acepta el desafío creando soluciones innovadoras para la agricultura y la higiene ambiental, permitiéndonos cultivar y llevar adelante las buenas costumbres que ayuden a mejorar el planeta, buscando lograr el equilibrio ideal para el éxito de los agricultores, la agricultura y las generaciones futuras.</p>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div className="servicios py-5">
            <div className="blue-servicio"></div>
            <div className="little-gold"></div>
            <div className="big-gold"></div>
            <div className="grey-servicios"></div>
            <img src="/assets/gold-wave.svg" alt="deco" className="wave-03" />
            <div className="container">
              <div className="grey-servicio"></div>
              <h3>Productos para cada etapa del crecimiento</h3>
              <div className="row py-3 mx-3 mx-md-0">
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="0 a 10 días antes de la siembra" imgEtapa="../assets/planta00.jpg" claseImgEtapa2=" " 
                  href01="https://agriculture.basf.com/ar/es/proteccion-de-cultivos-y-semillas/productos/heat.html" imgparter01="../assets/basf-01.svg" 
                  href02="https://agriculture.basf.com/ar/es/proteccion-de-cultivos-y-semillas/productos/zidua-pack.html" imgpartner01="basf-siembra"imgpartner02="basf-siembra" imgparter02="../assets/basf-02.svg" />
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                <ModuloProductoEtapa titleEtapa="Siembra" imgEtapa="../assets/planta0.jpg" claseImgEtapa6siembra=" " 
                imgparter01="../assets/basf-03.svg" href01="https://agriculture.basf.com/ar/es/proteccion-de-cultivos-y-semillas/productos/gestus.html" imgpartner201="basf-siembra" 
                imgparter02="../assets/basf-06.svg" href02="https://agriculture.basf.com/ar/es/proteccion-de-cultivos-y-semillas/productos/hicoat-super-mani.html" imgpartner202="basf-siembra" 
                imgparter03="../assets/basf-04.svg" href03="https://agriculture.basf.com/ar/es/proteccion-de-cultivos-y-semillas/productos/color-coat.html" imgpartner203="basf-siembra"
                imgparter04="../assets/basf-07.svg" href04="https://agriculture.basf.com/ar/es/proteccion-de-cultivos-y-semillas/productos/hicoat-super-mani.html" imgpartner204="basf-siembra"
                imgparter05="../assets/basf-05.svg" href05="https://agriculture.basf.com/ar/es/proteccion-de-cultivos-y-semillas/productos/adhere-mani.html" imgpartner205="basf-siembra" 
                imgparter06="../assets/basf-08.svg" href06="https://agriculture.basf.com/ar/es/proteccion-de-cultivos-y-semillas/productos/histick-2-mani.html" imgpartner206="basf-siembra"/>
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="V2" imgEtapa="../assets/planta3.jpg" claseImgEtapa32=" " 
                  imgparter01="../assets/basf-09.svg" href01="https://agriculture.basf.com/ar/es/proteccion-de-cultivos-y-semillas/productos/basagran.html" imgpartner="basf-siembra" 
                  imgparter02="../assets/basf-10.svg" href02="https://agriculture.basf.com/ar/es/proteccion-de-cultivos-y-semillas/productos/zynion.html"
                  imgparter03="../assets/basf-11.svg" href03="https://agriculture.basf.com/ar/es/proteccion-de-cultivos-y-semillas/productos/cadre-70-dg.html"/>
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="V6 - R1" imgEtapa="../assets/planta4.jpg" claseImgEtapa3v6=" " 
                  imgparter01="../assets/basf-12.svg" href01="https://agriculture.basf.com/ar/es/proteccion-de-cultivos-y-semillas/productos/bellis.html" imgpartner="basf-siembra"
                  imgparter02="../assets/basf-13.svg" href02="https://agriculture.basf.com/ar/es/proteccion-de-cultivos-y-semillas/productos/opera.html"
                  imgparter03="../assets/basf-14.svg" href03="https://agriculture.basf.com/ar/es/proteccion-de-cultivos-y-semillas/productos/orquesta-ultra.html"/>
                </div>
                <div className="col-md-6 px-0 px-md-auto">
                  <ModuloProductoEtapa titleEtapa="R3" imgEtapa="../assets/planta6.jpg" claseImgEtapa32=" " 
                  imgparter01="../assets/basf-15.svg" href01="https://agriculture.basf.com/ar/es/proteccion-de-cultivos-y-semillas/productos/orquesta-ultra.html" imgpartner="basf-siembra" 
                  imgparter02="../assets/basf-16.svg" href02="https://agriculture.basf.com/ar/es/proteccion-de-cultivos-y-semillas/productos/opera.html"
                  imgparter03="../assets/basf-17.svg" href03="https://agriculture.basf.com/ar/es/proteccion-de-cultivos-y-semillas/productos/duett-plus.html"/>
                </div>
              </div>
            </div>
          </div>
          <div className="Beneficios py-5 d-md-flex">
            <img src="/assets/gold-beneficios.svg" alt="deco" className="gold-beneficios"/>
            <div className="container">
              <h4 className="mb-4">Beneficios</h4>
              {/* <CarouselAliados/> */}
              <div className="row">
                <div className="col-md-4">
                  <a href="https://www.multiplicabasf.com.ar/registrate/" target="_blank">
                    <div className="cuadro-container">
                      <div className="d-flex flex-column">
                        <img src="/assets/multiplica.png" alt="multiplica"/>
                        <p>Comprando productos del portafolio de BASF podés acumular puntos para canjear por camionetas, tecnología y mucho más en nuestro catálogo de Multiplica, el programa de beneficios y descuentos más importante del Agro Argentino.<br/> Registrate  <a href="https://www.multiplicabasf.com.ar/registrate/"target="_blank">acá</a> y sé parte. </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4">
                  <a href="https://agriculture.basf.com/ar/es/sustentabilidad/Academia-Agro.html" target="_blank">
                    <div className="cuadro-container">
                      <div className="d-flex flex-column">
                        <img src="/assets/academia-agro.svg" alt="/academia-agro"/>
                        <p>Un espacio de capacitación, charlas interactivas, materiales descargables para las distintas problemáticas del campo y su gestión.<br/><a href="https://agriculture.basf.com/ar/es/sustentabilidad/Academia-Agro.html" target="_blank">Conocé más...</a></p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4">
                  <a href="https://wa.me/5491134215552" target="_blank">
                    <div className="cuadro-container">
                      <div className="d-flex flex-column">
                        <img src="/assets/linea-wpp.png" alt="linea-wpp"/>
                        <p>Si tenés dudas de alguno de nuestros productos podés contactarte vía WhatsApp con el centro de atención al cliente de BASF y recibir más información.</p>
                        <p className="dest">+54 911 3421 5552</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="cta-aliados py-5">
            <div className="container">
              <div className="col-12">
                <div href="#" className="interesado-aliados">
                  <Link to="/contacto">
                    <div className="row justify-content-center align-items-center">
                      <p>SI ESTÁS INTERESADO COMUNICATE CON NOSOTROS</p>
                      <img src="/assets/arrow-next.svg" alt="arrow" className="arrow-cta" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer/>
      </>
    );
  }
  
  export default Basf;
  