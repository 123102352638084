import React from 'react';
import Home from './components/Home'
import SobreMani from './components/SobreMani'
import Programas from './components/Programas'
import Producto  from './components/Producto'
import Contacto from './components/Contacto'
import Fedea from './components/Fedea'
import Basf from './components/Basf'
import Bayer from './components/Bayer'
import Corteva from './components/Corteva'
import Nova from './components/Nova'
import OroVerde from './components/OroVerde'
import Summit from './components/Summit'
import Syngenta from './components/Syngenta'



import {
  BrowserRouter as Router,HashRouter,
  Switch,
  Route,
  Link
} from "react-router-dom";



function Routes() {
    return (
      <div className="Routes">
            <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/sobremani">
            <SobreMani />
          </Route>
          <Route path="/programas">
            <Programas />
          </Route>
          <Route path="/producto">
            <Producto />
          </Route>
          <Route path="/contacto">
            <Contacto />
          </Route>
          <Route path="/fedea">
            <Fedea />
          </Route>
          <Route path="/basf">
            <Basf />
          </Route>
          <Route path="/bayer">
            <Bayer />
          </Route>
          <Route path="/corteva">
            <Corteva />
          </Route>
          <Route path="/nova">
            <Nova />
          </Route>
          <Route path="/oroverde">
            <OroVerde />
          </Route>
          <Route path="/summit">
            <Summit />
          </Route>
          <Route path="/syngenta">
            <Syngenta />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
      </div>
    );
  }
  export default Routes;
  