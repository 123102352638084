import React from 'react';
import Highlights from './Highlights'
import DropdownProducto from './DropdownProducto'
import DropdownProductoDos from './DropdownProductoDos'
import Grafico from './Grafico'
import WhatsApp from './WhatsApp';
import Footer from './Footer';
import {BrowserRouter as Router, HashRouter, Switch,Route,Link} from "react-router-dom"
import '../scss/bootstrap.scss';
import '../scss/Producto.scss';


function  Producto() {
    return (
      <>
      <WhatsApp/>
      <div className="Producto">
      <div className="space"></div>
        <section className="producto-intro">
          <div className="gold-producto-01"></div>
          <div className="blue-producto-01"></div>
          <img src="/assets/deco-grey.svg" alt="deco" className="grey-producto" />
          <div className="container">
            <p className="nameSection">Producto</p>
              <div className="row align-items-center"> 
                <div className="col-md-6 info-producto mb-5">
                <Highlights classNameH="interna" firstLineH="El mejo " firstLine="r maní del" secondLineH="mund"secondLine="o. Orgullo de" thirthLineH=" " thirthLine="nuestra tierra."/>
                <p>Argentina se ha consolidado a nivel mundial como el mayor exportador de maní de calidad, y es precisamente ese prestigio logrado por su gran calidad, lo que distingue al Maní Argentino como el mejor maní del mundo y le ha abierto puertas en los principales mercados.</p>
                </div>
                <div className="col-md-6 ">
                  <div className="numeros-producto">
                    <p className="title-numeros mt-3">EXPORTACIONES</p>
                    <ul>
                      <li>+ 400.000 Tn</li>
                      <li>+ 100 Destinos</li>
                      <li>+ 1,000 u$Millones</li>
                    </ul>
                    <p>95% del total del maní producido en todo el país se destina al comercio exterior, siendo la Unión Europea el principal mercado de interés.</p>
                  </div>
                </div>
              </div>
          </div>
        </section>

        <section className="destacados py-5">
          <img src="/assets/gold-wave.svg" alt="deco" className="wave-destacado" />
          <div className="container">
            <p className="title-destacado d-none d-md-flex">Entre las manufacturas elaboradas que se exportan<br/> se destacan:</p>
            <p className="title-destacado d-md-none">Entre las manufacturas elaboradas que se exportan se destacan:</p>
            <div className="row">
              <div className="col-md-6">
                <div className="elaborado">
                  <p>Maní Confitería <span>(Shelled, Blanched, Splits, Chopped, Sliced)</span></p>
                </div>
                <div className="elaborado">
                  <p>Maní Preparado y/o Saborizado <span>(Snacks)</span></p>
                </div>
                <div className="elaborado">
                  <p>Pasta de maní</p>
                </div>
                <div className="elaborado">
                  <p>Manteca de maní</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="elaborado">
                  <p>Aceite de maní, crudo y refinado</p>
                </div>
                <div className="elaborado">
                  <p>Harina de maní</p>
                </div>
                <div className="elaborado">
                  <p>Pallets y expellers de maní</p>
                </div>
                <img src="/assets/sello-mapa.svg" alt="sello" className="sello-mapa"/>
              </div>
            </div>
          </div>
        </section>
        <section className="calidad py-5">
          <div className="blue-calidad"></div>
          <div className="gold-calidad-01"></div>
          <div className="gold-calidad-02"></div>
          <img src="/assets/gold-wave.svg" alt="deco" className="wave-calidad" />
          <div className="container">
            <h3 className="title-calidad">¿Cómo se mide el rendimiento?</h3>
            <DropdownProducto titleDropProduct="UN CULTÍGENO SELECCIONADO" dataTarget="product1" infoInterior="El maní es un cultígeno, un cultivo domesticado históricamente por el hombre, elaborándolo originalmente con un fin alimenticio y su posterior desarrollo comercial." bajada="Para desarrollar un alimento saludable y de producción sustentable." textOne="El maní es un cultígeno, un cultivo domesticado históricamente por el hombre, elaborándolo originalmente con un fin alimenticio y su posterior desarrollo comercial." textTwo="Como especie leguminosa enriquece el suelo con nitrógeno, brindando ventajas con su rol en la rotación de cultivos por siembra directa, contribuyendo a mantener un balance nutricional equilibrado colaborando a la sustentabilidad del sistema productivo."/>
            <DropdownProductoDos titleDropProduct="RENDIMIENTO CONFITERÍA" dataTarget="product2" infoInterior="" bajada="Se indica en relación porcentual cuanto representa el peso de los granos en el total del peso de las cajas que llegan a PDM." textOne="Contrariamente a lo que muchos suponen, el Maní Confitería no es un commodity como erróneamente suele considerarse, sino un producto alimentario elaborado, una manufactura de origen agrícola que cuenta en su proceso final con un extraordinario componente de valor agregado."  titleOne="Maní confitería" subtitleOne="Un producto alimentario elaborado de extraordinario valor agregado"/>
          </div>
        </section>
        <section className="historia">
          <div className="white-historia"></div>
          <img src="/assets/deco-gold.svg" alt="deco" className="deco-historia"/>
          <div className="d-none d-md-block white-desktop"></div>
          <div className="bg-grey-historia"></div>
          <div className="container pt-5">
            <div className="d-none d-md-block pt-5">
              <Highlights classNameH="interna" firstLineH="MÁS DE 10" firstLine="0 AÑOS DE HISTORIA" secondLineH="Para producir un cultivo de excel"secondLine="encia."/>
              <img src="/assets/sello-arg.svg" alt="arg" className="sello-arg-historia" />
            </div>
            <div className="d-md-none mb-5 pb-5">
              <Highlights classNameH="interna" firstLineH="MÁS DE 10" firstLine="0 AÑOS DE" secondLineH="HISTORI"secondLine="A. Para" thirthLineH="producir un cultiv" thirthLine="o" forthLineH=" " forthLine="de excelencia."/>
              <img src="/assets/sello-arg.svg" alt="arg" className="sello-arg-historia" />
            </div>
            <p className="title-rinde mt-5">Rinde Histórico</p>
          <div className="row">
            <div className="col-md-6">
              <p>Hoy estamos escribiendo uno de los capítulos más importantes en la historia de la producción del cultivo de maní en nuestro país, frente a una gran demanda internacional y el desafío de mantener una calidad superior distintiva, lo cual representa una verdadera oportunidad para que nuevos productores sean también partícipes del éxito y sumen su pasión, uniéndose a la gran familia manisera argentina, reconocida a nivel internacional por producir nada menos que “el mejor maní del mundo”.</p>
            </div>
            <div className="col-md-6">
                <p>Esta experiencia inicialmente tuvo su origen en nuestro país con un grupo de apasionados agricultores que, hacia fines de 1896, apostaron al potencial de nuestro rico suelo amalgamado con las propiedades únicas del cultivo de maní. Desarrollándose, superando los desafíos, mejorando los procesos de la cosecha, invirtiendo en tecnología para lograr soluciones innovadoras hasta alcanzar la perfección del cultivo con los maníes Alto Oleico, siendo los más requeridos en los mercados internacionales.</p>
            </div>
          </div>
          </div>
        </section>
        <section>
          <div className="container">
            <Grafico/>
          </div>
        </section>
        <section className="ctas py-5 mt-5">
          <div className="gold-ctas"></div>
          <div className="blue-ctas"></div>
          <img src="/assets/lineas_azules.svg" alt="lineas" className="lineas-azules-ctas" />
          <div className="container">
            <div className="row">
              <div className="col-md-6 my-2">
                <div className="cta-producto">
                  <p className="title-cta">Comenza a cultivar maní!</p>
                  <p>Comenza a cultivar maní! Tenemos todas las opciones para el éxito de tu inicio en el cultivo.</p>
                  <Link to="/programas">
                    <p className="button mb-0 row justify-content-between">PROGRAMAS
                    <img src="/assets/arrow-next.svg" alt="arrow"/>
                    </p>
                  </Link>
                </div>
              </div>
              <div className="col-md-6 my-2">
                <div className="cta-producto">
                  <p className="title-cta">Alquilá</p>
                  <p>¿Querés tener maní en tu campo pero no quieres mantener el cultivo? Llamanos y te alquilamos el campo</p>
                  <Link to="/contacto">
                    <p className="button mb-0 row justify-content-between">CONTACTANOS
                    <img src="/assets/arrow-next.svg" alt="arrow"/>
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer/>
      </>
    );
  }
  
  export default Producto;
  