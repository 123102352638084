import React from 'react';
import Carousel  from 'react-elastic-carousel'
import '../scss/CarouselAliados.scss';

function CarouselAliados() {
    return (
    <>
    <div className="">
        <Carousel breakPoints={[{ width: 1, itemsToShow: 1 },
        { width: 400, itemsToShow: 3, itemsToScroll: 2, pagination: true },
        { width: 850, itemsToShow: 3 },]}>
            <div className="beneficio">
                <a href="https://www.corteva.com.ar/" target="_blank">
                    <img className="d-block mb-3" src="/assets/logo-corteva.png" alt="First slide"/>
                    <p>Encontrá todos los productos de CORTEVA con el mejor precio y financiación.</p>
                </a>
            </div>
            <div className="beneficio">
                <a href="https://www.axionenergy.com/Paginas/agro.aspx" target="_blank">
                    <img className="d-block mb-3" src="/assets/logo-axion.png" alt="First slide"/>
                    <p>Te ofrecemos calidad y tecnología en Combustibles Axion Agro y Lubricantes Castrol.</p>
                </a>
            </div>
            <div className="beneficio">
                <a href="https://www.brevant.com.ar/" target="_blank">
                    <img className="d-block mb-3" src="/assets/logo-brevant.svg" alt="First slide"/>
                    <p>Semillas de maíz Brevant. Exelentes rendimientos en maíz temprano y tardío.</p>
                </a>
            </div>
            <div className="beneficio">
                <a href="https://www.fedea.com.ar/" target="_blank">
                    <img className="d-block mb-3 fedea-img" src="/assets/logo-fedea.svg" alt="First slide"/>
                    <p>Acopiamos y comercializamos todos los granos y cereales. Buscamos la mejor alternativa de negocio, ya sea con entrega en nuestras plantas o en puertos.</p>
                </a>
            </div>
        </Carousel>
    </div>
    </>
    );
  }
  
  export default CarouselAliados;