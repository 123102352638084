
import React, { useEffect, useState } from 'react';
import InfoCiclo from './InfoCiclo'

import Carousel, { type, onClick, isEdge, consts }  from 'react-elastic-carousel'
import '../scss/Video.scss';
     

function Video(props){
    
    const urlVid = '/assets/video.mp4';
    const urlVidBack = '/assets/reversed.mp4';

    const arrTimeCodes = [0,1,4.6,5.5,7.6,14.4,16,18.9,19.9,20.4,20.6,23,25];
    
    const [currentPeanutPlantStatus, setCurrentPeanutPlantStatus] = useState(1)

    useEffect(() => {
          
        
        const vid = document.getElementById('video');
        const vidBack = document.getElementById('videoBack');
        
        const btnFor = document.getElementById('btnFor');
        const btnBack = document.getElementById('btnBack');

        const btnDay0 = document.getElementById('day0');
        const btnDay1 = document.getElementById('day1');
        const btnDay2 = document.getElementById('day2');
        const btnDay3 = document.getElementById('day3');
        const btnDay4 = document.getElementById('day4');
        const btnDay5 = document.getElementById('day5');
        const btnDay6 = document.getElementById('day6');
        const btnDay7 = document.getElementById('day7');
        const btnDay8 = document.getElementById('day8');
        const btnDay9 = document.getElementById('day9');
        const btnDay10 = document.getElementById('day10');
        const btnDay11 = document.getElementById('day11');
        const btnDay12 = document.getElementById('day12');
        const btnDay13 = document.getElementById('day13');

        
        let currentTimeStation = 0;
        let tiempoactual;   
        
        //Ajax Video
        let reqVid = new XMLHttpRequest();
        reqVid.open('GET', urlVid, true);
        reqVid.responseType = 'blob';

        let reqVidBack = new XMLHttpRequest();
        reqVidBack.open('GET', urlVidBack, true);
        reqVidBack.responseType = 'blob';

        reqVid.onload = function() {
        
            if (this.status === 200) {
                let videoBlob = this.response;
                vid.src = (window.webkitURL || window.URL).createObjectURL(videoBlob);
                vid.load();
                //vid.play();
            }
        }

        reqVidBack.onload = function() {
        
            if (this.status === 200) {
                let videoBlobBack = this.response;
                vidBack.src = (window.webkitURL || window.URL).createObjectURL(videoBlobBack);
                vidBack.load();
                
            }
        }

        reqVid.send();
        reqVidBack.send();
        //Final Ajax

                
        //Get duracion del video
        var promiseDuration = new Promise((resolve,reject)=>{
            vid.addEventListener('loadeddata',()=>{
            var duracion = vid.duration;
            resolve(duracion);
            })
        });

        function showVid(){
            
            vid.removeEventListener('play',showVid);
            vid.style.zIndex = 1 
            vidBack.style.zIndex = 0;
            /*vid.style.display = "block" 
            vidBack.style.display = "none";*/
        }

        function showVidBack(){
            
            vidBack.removeEventListener('play',showVidBack);
            vidBack.style.zIndex = 1 
            vid.style.zIndex = 0;
        }
        
        function playVid(){
            
            vid.removeEventListener('timeupdate',playVid);
            vid.play();      
        }
        
        function playVidBack(){
            
            vidBack.removeEventListener('timeupdate',playVidBack);
            vidBack.play();      
        }
        
        
        
        promiseDuration.then((duracion)=>{
            //fix bug raro no entiendo;
            vid.style.zIndex = 1;
            vid.style.backgroundImage =  "url('/assets/planta0.jpg')";
            vid.style.backgroundSize = "200px";
            vid.style.backgroundPosition = "center";
            vid.style.backgroundRepeat = "no-repeat";
            vidBack.style.backgroundImage =  "url('/assets/planta0.jpg')";
            vidBack.style.backgroundSize = "200px";
            vidBack.style.backgroundRepeat = "no-repeat";
            vidBack.style.backgroundPosition = "center";
            //fin fix bug
            
            
            let arrTimeCodesBack = arrTimeCodes.map((value)=>{
                return(duracion-value);
            });

            function changeBackground(station){
                let urlBackground = "/assets/planta" + station + ".jpg";
                vid.style.backgroundImage = `url(${urlBackground})`;
                vidBack.style.backgroundImage = `url(${urlBackground})`; 
                
                // console.log(vid.style.backgroundImage);
            }           
            
            function changeVidTime(){
                
                tiempoactual = vidBack.currentTime;
                vid.currentTime = duracion - tiempoactual  ; 
                
            }

            function changeVidBackTime(){
                
                tiempoactual = vid.currentTime;
                vidBack.currentTime = duracion - tiempoactual ; 
                
            }

            function checkVidTime(station){
              if(vid.currentTime>=arrTimeCodes[currentTimeStation]){
                  vid.removeEventListener('timeupdate',checkVidTime);
                  vid.pause();
                  
              }  
            
            }
            
            function checkVidBackTime(station){
                if(vidBack.currentTime>=arrTimeCodesBack[currentTimeStation]){
                    vidBack.removeEventListener('timeupdate',checkVidBackTime);
                    vidBack.pause();
                }  

            }
            // function changeText(){
            //     var divfadeout = document.getElementById("divfadeout")
            //     if (divfadeout.style.display ==="none"){
            //         divfadeout.style.display = "block";
            //     } else{
            //         divfadeout.style.display = "none";
            //     }
            // }
            function stationManager(station){
               
                if(currentTimeStation<station){
                    
                    if(vid.style.zIndex!=1){
                        
                        currentTimeStation = station;
                        vidBack.pause();
                        requestAnimationFrame(changeVidTime);
                        vid.addEventListener('timeupdate',playVid);
                        vid.addEventListener('play',showVid);
                        
                        vid.addEventListener('timeupdate',checkVidTime);
                        
                        changeBackground(station);
                        return;
                    }

                    if(vidBack.style.zIndex!=1){
                        currentTimeStation = station;
                        
                        vid.play();
                        vid.addEventListener('timeupdate',()=>{
                            checkVidTime(station)
                        });
                        changeBackground(station);
                        return;
                    }

                }
                
                if(currentTimeStation>station){
                    
                    if(vid.style.zIndex!=1){
                        
                        currentTimeStation = station;
                        vidBack.play();
                        vidBack.addEventListener('timeupdate',()=>{
                            checkVidBackTime(station);
                        });
                        changeBackground(station);
                        return;
                    }

                    if(vidBack.style.zIndex!=1){
                        
                        currentTimeStation = station;
                        vid.pause();
                        requestAnimationFrame(changeVidBackTime);
                        vidBack.addEventListener('timeupdate',playVidBack);
                        vidBack.addEventListener('play',showVidBack);
                        vidBack.addEventListener('timeupdate',checkVidBackTime);
                        changeBackground(station);
                        return;
                    }
                    
                }
            }
            btnFor.addEventListener('click',()=>{
                
                if(currentTimeStation!=arrTimeCodes.length){
                    stationManager(currentTimeStation + 1);
                }
            });
                
            btnBack.addEventListener('click', ()=>{
                
                if(currentTimeStation>0){
                    stationManager(currentTimeStation - 1);
                }
            });

            btnDay0.addEventListener('click', ()=>{
                stationManager(0); 
            });

            btnDay1.addEventListener('click', ()=>{
                stationManager(1);
            });

            btnDay2.addEventListener('click', ()=>{
                stationManager(2);
            });

            btnDay3.addEventListener('click', ()=>{
                stationManager(3);
            });

            btnDay4.addEventListener('click', ()=>{
                stationManager(4);
            });

            btnDay5.addEventListener('click', ()=>{
                stationManager(5);
            });

            btnDay6.addEventListener('click', ()=>{
                stationManager(6);
            });

            btnDay7.addEventListener('click', ()=>{
                stationManager(7);
            });

            btnDay8.addEventListener('click', ()=>{
                stationManager(8);
            });

            btnDay9.addEventListener('click', ()=>{
                stationManager(9);
            });

            btnDay10.addEventListener('click', ()=>{
                stationManager(10);
            });

            btnDay11.addEventListener('click', ()=>{
                stationManager(11);
            });

            btnDay12.addEventListener('click', ()=>{
                stationManager(12);
            });

            btnDay13.addEventListener('click', ()=>{
                stationManager(13);
            });
        });
    }, []);
    
    let carousel
    let i = 1
    return( 
        <>
        <div className="container position-relative">
            <div id="divfadeout">
                <InfoCiclo currentPeanutPlantStatus={currentPeanutPlantStatus}/>
            </div>
        </div> 
            <div className="videoBox">
                <div className="video-position">
                    <video  className ="video" id ="video" muted playsInline>
                        <source src="" type="video/mp4"  fileConfig={{ attributes: { playsinline: true }}}/>
                    </video>
                    <video  className="videoBack" id ="videoBack" muted playsInline>
                        <source src="" type="video/mp4"  fileConfig={{ attributes: { playsinline: true }}}/>
                    </video>
                </div>	
                    <img className="tierra d-none d-md-block" src="/assets/tierra.svg"/>
                    <img className="tierra d-md-none" src="/assets/tierra-mobile.svg"/>
  
                <div id ="days" className="">
                    <div className="container">
                        <div className ="buttons" id ="buttons">
                            <button className="btnBack" id ="btnBack"  
                            onClick=
                            {(event) => {
                                carousel.slidePrev()
                                setCurrentPeanutPlantStatus(currentPeanutPlantStatus - 1);
                                }}>
                                <img src="/assets/arrow-left-ciclo.svg"/></button>
                            <button className="btnFor" id ="btnFor" 
                            onClick=
                            {(event) => {
                                carousel.slideNext();
                                setCurrentPeanutPlantStatus(currentPeanutPlantStatus + 1);
                                }}>
                                <img src="/assets/arrow-right-ciclo.svg"/></button>
                        </div>
                    </div>
                    <div className="w-100">
                        <div className="w-100">
                            <div className="container">
                                <div className="position-relative">
                                    <div className="specialDot"><div className="goldDot"></div></div>
                                </div>
                            </div>
                            <div className="lineTimeLine"></div>
                            <div className="container">
                            <div className="dots">
                                <p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p><p className="d-none d-md-block">.</p>
                            </div>

                            </div>
                        </div>
                        <div className="container">
                            <Carousel 
                            ref={ref => (carousel = ref)}
                            focusOnSelect={true}
                            // carousel.slidePrev()
                                // if(pageIndex !== 1){
                                //     for(let i= currentItem; i <= 1 ;i++){
                                //         carousel.slidePrev()
                                //     }
                                // }
                            // onNextEnd={(currentItem, pageIndex) => setCurrentPeanutPlantStatus(currentItem.index)}
                            // onPrevEnd={(currentItem, pageIndex) => setCurrentPeanutPlantStatus(currentItem.index)}
                            // renderArrow={this.myArrow}
                            // itemPosition={consts.END}
                            showEmptySlots
                            breakPoints={[{ width: 1, itemsToShow: 1, pagination: false},
                                        { width: 400, itemsToShow: 3, itemsToScroll: 1, pagination: false},
                                        { width: 850, itemsToShow: 5, itemsToScroll: 1,pagination: false },]}>
                                <div className="row">
                                    <button className={`button-day ${currentPeanutPlantStatus === 1 ? 'focusBotton':''}`} id="day0" onClick={() => setCurrentPeanutPlantStatus(1)}><div className="rayita" ></div>Día 0 <br/><span>Pre Siembra</span></button>
                                </div>
                                <div className="row">
                                    <button  className={`button-day ${currentPeanutPlantStatus === 2 ? 'focusBotton':''}`} id="day1" onClick={() => setCurrentPeanutPlantStatus(2)}><div className="rayita" ></div>Día 1 <br/><span>Pre Emergencia</span></button>
                                    
                                </div>
                                <div className="row">
                                    
                                    <button  className={`button-day ${currentPeanutPlantStatus === 3 ? 'focusBotton':''}`} id="day2" onClick={() => setCurrentPeanutPlantStatus(3)}><div className="rayita"></div>Día 12 <br/><span>VE EMERGENCIA</span></button>
                                    
                                </div>
                                <div className="row">
                                    
                                    <button  className={`button-day ${currentPeanutPlantStatus === 4 ? 'focusBotton':''}`} id="day3" onClick={() => setCurrentPeanutPlantStatus(4)}><div className="rayita"></div>Día 15 <br/><span>V0 COTILEDONAR</span></button>
                                    
                                </div>
                                <div className="row">
                                    
                                    <button  className={`button-day ${currentPeanutPlantStatus === 5 ? 'focusBotton':''}`} id="day4" onClick={() => setCurrentPeanutPlantStatus(5)}><div className="rayita"></div>Día 18 <br/><span>V1 PRIMERA HOJA</span></button>
                                    
                                </div>
                                <div className="row">
                                    
                                    <button  className={`button-day ${currentPeanutPlantStatus === 6 ? 'focusBotton':''}`} id="day5" onClick={() => setCurrentPeanutPlantStatus(6)}><div className="rayita"></div>Día 35 <br/><span>VN "N"</span></button>
                                    
                                </div>
                                <div className="row">
                                    
                                    <button  className={`button-day ${currentPeanutPlantStatus === 7 ? 'focusBotton':''}`} id="day6" onClick={() => setCurrentPeanutPlantStatus(7)}><div className="rayita"></div>Día 40 <br/><span>R1 FLORACION</span></button>
                                    
                                </div>
                                <div className="row">
                                    
                                    <button  className={`button-day ${currentPeanutPlantStatus === 8 ? 'focusBotton':''}`} id="day7" onClick={() => setCurrentPeanutPlantStatus(8)}><div className="rayita"></div>Día 50 <br/><span>R2 FORMACION CLAVO</span></button>
                                    
                                </div>
                                <div className="row">
                                    
                                    <button  className={`button-day ${currentPeanutPlantStatus === 9 ? 'focusBotton':''}`} id="day8" onClick={() => setCurrentPeanutPlantStatus(9)}><div className="rayita"></div>Día 60 <br/><span>R3 FORMACION FRUTO</span></button>
                                    
                                </div>
                                <div className="row">
                                    
                                    <button  className={`button-day ${currentPeanutPlantStatus === 10 ? 'focusBotton':''}`} id="day9" onClick={() => setCurrentPeanutPlantStatus(10)}><div className="rayita"></div>Día 70 <br/><span>R4 FRUTO COMPLETO</span></button>
                                    
                                </div>
                                <div className="row">
                                    
                                    <button  className={`button-day ${currentPeanutPlantStatus === 11 ? 'focusBotton':''}`} id="day10" onClick={() => setCurrentPeanutPlantStatus(11)}><div className="rayita"></div>Día 80 <br/><span>R5 FORMACIÓN SEMILLAS</span></button>
                                    
                                </div>
                                <div className="row">
                                    
                                    <button  className={`button-day ${currentPeanutPlantStatus === 12 ? 'focusBotton':''}`} id="day11" onClick={() => setCurrentPeanutPlantStatus(12)}><div className="rayita"></div>Día 85 <br/><span>R6 SEMILLA</span></button>
                                    
                                </div>
                                <div className="row">
                                    
                                    <button  className={`button-day ${currentPeanutPlantStatus === 13 ? 'focusBotton':''}`} id="day12" onClick={() => setCurrentPeanutPlantStatus(13)}><div className="rayita"></div>Día 120 <br/><span>R7 MADUREZ</span></button>
                                    
                                </div>
                                <div className="row">
                                    
                                    <button  className={`button-day ${currentPeanutPlantStatus === 14 ? 'focusBotton':''}`} id="day13" onClick={() => setCurrentPeanutPlantStatus(14)}><div className="rayita"></div>Día 160 <br/><span>R8 COSECHA</span></button>
                                </div>
                                
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div> 
        </>
    );
        

} 

export default Video;




