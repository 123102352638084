import React from 'react';
import '../scss/bootstrap.scss';
import '../scss/Footer.scss';

function Footer() { 
    return (
      <div className="footer py-5">
        <div className="container">
          <div className="row">
              <div className="col-md-4 d-flex justify-content-start justify-content-md-center align-items-center prodeman-sello">
                <a href="http://prodeman.com/" target="_blank">
                  <img src="./assets/selloprodeman.png" className="sello"/>
                </a>
              </div>
              <div className="col-md-4">
                <h5 className="titleFooter">Contacto</h5>
                <p>contacto@maniseros.com</p>
              </div>
              <div className="col-md-4">
                <h5 className="titleFooter">Redes</h5>
                <p>¡Seguinos!</p>
                <div className="row px-2">
                    <a href="https://www.instagram.com/maniserosok/" target="_blank"><img src="/assets/sm-insta.svg" className="icon-sm" alt="Insta"/></a>
                    <a href="https://twitter.com/maniserosOK" target="_blank"><img src="/assets/sm-twitter.svg" className="icon-sm" alt="Twitter"/></a>
                    <a href="https://www.facebook.com/mnsrs.pdm" target="_blank"><img src="/assets/sm-face.svg" className="icon-sm" alt="Facebook"/></a>
                    <a href="https://open.spotify.com/user/7dkgh65xfz81b8ohyp1kkx9ac?si=EWCZW2a6TcqdGTpmv1y0OA" target="_blank"><img src="/assets/sm-spotify.svg" className="icon-sm" alt="Spotify"/></a>
                </div>
              </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default Footer;
  