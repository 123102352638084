import React from 'react';
import Highlights from './Highlights'
import ProgramaFedea from './ProgramaFedea'
import ProgramaLlave from './ProgramaLlave'
import ExitoProgramas from './ExitoProgramas'
import ContactoProgramas from './ContactoProgramas'
import Footer from './Footer';
import '../scss/Programas.scss';

function Programas() {
    return (
      <>
      <div className="Programas">
        <div className="space"></div>
        <div className="container">
        <section className="introProgramas">
              <p className="nameSection">Programas Maniseros</p>
          <div className="row">
            <div className="col-md-6 deco-internas">
              <Highlights classNameH="interna" firstLineH="Ventaja" firstLine="s y beneficios" secondLineH="de una propu"secondLine="esta" thirthLineH="superad" thirthLine="ora."/>
              <img className="deco-programas-01" src="./assets/deco-gold.svg"/>
            </div>
            <div className="col-md-6 d-none d-md-flex">
              <p className="descPrograms">Los productores que participen descubrirán todo el potencial que representa el sumarse a la familia manisera, logrando incrementar la productividad de su agricultura y los beneficios para el suelo de su campo, mejorando la sustentabilidad del sistema productivo al incorporar este apasionante cultivo. Dos tentadoras propuestas pensadas a la medida del perfil del productor agropecuario que se necesita para ampliar la producción del cultivo y satisfacer la creciente demanda, manteniendo el standard de calidad premium reconocido al maní argentino y requerido internacionalmente.</p>
            </div>
          </div>
        </section>
        <section className="infoProgramas py-5">
          <div className="detalle-blue"></div>
          <img src="/assets/deco-grey.svg" alt="deco" className="grey-deco-programas"/>
          <div className="row justify-content-between">
            <div className="col-md-5 text-left bg-p mx-3 mx-md-0 mb-5 mb-md-0">
              <div className="row flex-md-column">
                <img src="/assets/sello-fedea.svg" alt="sello Fedea" className="sello-programas"/>
                <h2 className="titleprograma">1. FEDEA & PRODEMAN</h2>
                <p className="d-none d-md-block textprograma">Sé parte de la familia internacional de productores de maní</p>
              </div>
              <a href="#fedea" className="row botton-mas">
                <p>CONOCER MÁS</p>
                <img src="/assets/arrow-bottom.svg" alt="flecha" className="arrow-conocer"/>
              </a>
            </div>
            <div className="col-md-5 text-left bg-p mx-3 mx-md-0 mb-5 mb-md-0">
              <div className="row flex-md-column">
                <img src="/assets/llave-en-mano.svg" alt="sello Llave en Mano" className="sello-programas"/>
                <h2 className="titleprograma">2. Llave en Mano</h2>
                <p className="d-none d-md-block textprograma">Solución integral según áreas de interés.</p>
              </div>
              <a href="#llaveenmano" className="row botton-mas">
                <p>CONOCER MÁS</p>
                <img src="/assets/arrow-bottom.svg" alt="flecha" className="arrow-conocer"/>
              </a>
            </div>
          </div>
        </section>
        <div className="d-md-none">
              <p className="descPrograms">Los productores que participen descubrirán todo el potencial que representa el sumarse a la familia manisera, logrando incrementar la productividad de su agricultura y los beneficios para el suelo de su campo, mejorando la sustentabilidad del sistema productivo al incorporar este apasionante cultivo. Dos tentadoras propuestas pensadas a la medida del perfil del productor agropecuario que se necesita para ampliar la producción del cultivo y satisfacer la creciente demanda, manteniendo el standard de calidad premium reconocido al maní argentino y requerido internacionalmente.</p>
            </div>
        </div>
        <div className="bg-gold">
          <div className="bg-gold-color">
          </div>
        </div>
      </div>
      <div id="fedea" className="spacefedea">
      </div>
        <ProgramaFedea/>
        <div className="divisor-programas">
        <div className="row">
          <div className="bluedark"></div>
          <div className="gold"></div>

        </div>
        </div>
        <div id="llaveenmano" className="spacefedea">
          <div className="bg-grey-llave"></div>
        </div>
        <ProgramaLlave/>
        <ExitoProgramas/>
        <ContactoProgramas/>
        <Footer/>
      </>
    );
  }
  
  export default Programas;
  